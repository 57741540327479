import { SessionStorageService } from "./../../services/session.service";
import { MessageService } from "./../../services/message.service";
import { dataTable } from "./../../models/dataTable.model";
import { environment } from "./../../../environments/environment";
import { BookService } from "./../../services/book.service";
import { iUser, User } from "./../../models/user.model";
import { Router } from "@angular/router";
import { LocalStorageService } from "./../../services/storage.service";
import { SuccessOautResponse } from "./../../models/oauth.model";
import { OauthService } from "./../../services/oauthservice.service";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { renderFlagCheckIfStmt } from "@angular/compiler/src/render3/view/template";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  userName = new FormControl("", [Validators.required, Validators.email]);

  public form;
  subscriptions: Subscription[] = [];
  formPSwRecover;
  loading = false;
  user: iUser;
  version = environment.version;
  forgotPsw = false;
  pswSent = false;

  constructor(
    private fb: FormBuilder,
    private oauth: OauthService,
    private localstore: LocalStorageService,
    private session: SessionStorageService,
    private router: Router,
    private bookservice: BookService,
    private toast: ToastrService,
    private messageservice: MessageService
  ) {
    this.form = fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

    this.formPSwRecover = fb.group({
      changePSwEmail: ["", [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.localstore.clear();
    this.session.clear();
   }

  login(): void {
    this.loading = true;
    this.localstore.clear();
    this.session.clear();
    const sub = this.oauth.login(this.form.value.email, this.form.value.password).subscribe(
      (res: SuccessOautResponse) => {
        this.session.set("token", res);
        this.session.set("tokenTimestamp", Math.floor(Date.now() / 1000));
        this.setUser(res.access_token);
      },
      (err: any) => {
        this.loading = false;
        if (err.error.error === "invalid_request") {
          this.toast.warning(
            "Inserire username e password",
            "Controllare i campi obbligatori"
          );
        } else if (err.error.error === "invalid_grant") {
          this.toast.error(
            "Le credenziali inserite non sono valide",
            "Credenziali errate"
          );
        } else {
          this.toast.error(err.error.error);
        }
      }
    );

    this.subscriptions.push(sub);
  }

  async saveBooks() {
    if (this.user.isEditore() || this.user.isAdmin() || this.user.isCliente()) {
      const cache = await caches.open(environment.cache);

      cache.delete(this.bookservice.catalogoRequest());
      cache.delete(this.bookservice.getCollectionCached("collane"));
      cache.delete(this.bookservice.getCollectionCached("generi"));
      cache.delete(this.bookservice.getCollectionCached("argomenti"));
      cache.delete(this.bookservice.getCollectionCached("rilegature"));
      cache.delete(this.bookservice.getCollectionCached("lingue"));
      cache.delete(this.bookservice.getCollectionCached("categorie"));

      cache.add(this.bookservice.getCollectionCached("collane"));
      cache.add(this.bookservice.getCollectionCached("generi"));
      cache.add(this.bookservice.getCollectionCached("argomenti"));
      cache.add(this.bookservice.getCollectionCached("rilegature"));
      cache.add(this.bookservice.getCollectionCached("lingue"));
      cache.add(this.bookservice.getCollectionCached("categorie"));
      if (this.user.isEditore()) {
        cache.add(this.bookservice.catalogoRequest()).then(() => {
          this.messageservice.setEvent({ event: "catalogo-loaded" });
          this.localstore.set("catalogo-loaded", true);
          
        });
      }
    }
  }

  setUser(token) {
    const sub = this.oauth.getUser(token).subscribe((r: User) => {
      if (r.profile === "rappresentante") {
        this.notAllowedAccess();
      } else {
        this.session.set("usr", r);
        this.user = new iUser(r);
        this.router.navigateByUrl("");
        this.saveBooks();
      }
    });

    this.subscriptions.push(sub);
  }

  notAllowedAccess() {
    this.session.remove("token");
    this.session.remove("tokenTimestamp");
    this.toast.warning(
      "Il tuo profilo non è abilitato per accedere a questo portale"
    );
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  }

  changePsw() {
    const sub = this.oauth.pswRecover(this.formPSwRecover.value.changePSwEmail).subscribe({
      next: (data) => {
        this.pswSent = true;
      },
      error: (err) => {
        console.error(err);
        this.toast.error(`errore nell'invio della password`);
      },
    });

    this.subscriptions.push(sub)
  }

  toLoginForm(){
    this.pswSent = false;
    this.forgotPsw = false;
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
