<p class="m-4" style="color:#000000de">
  {{hintTooltip}}
</p>
<div class="ep_card">
  <div
    class="ep_card-header ep_card-header-icon"
    [ngClass]="
      type === 'libreria' ? 'ep_card-header-success' : type
    "
  >
    <div
      class="ep_card-icon"
    >
      <i class="material-icons">{{ icon }}</i>
    </div>
    <p class="ep_card-category">{{ title }}</p>
    <h3 class="ep_card-title">{{ subtitle }}</h3>

    <export-table
      [data]="data.rowsExport"
      *ngIf="export && data.rowsExport.length > 0"
    ></export-table>
  </div>
  <div class="ep_card-footer">
    <mat-spinner
      *ngIf="loading"
      [diameter]="30"
      class="mx-auto"
    ></mat-spinner>
    <div
      *ngIf="!loading"
      class="container-fluid"
      [ngClass]="
        type === 'libreria'
          ? { 'overflow-libreria': scroll }
          : { overflow: scroll }
      "
    >
      <table class="mytable">
        <tr class="rowTh">
          <th
            class="cell"
            *ngFor="let col of data.header"
          >
            {{ col }}
          </th>
        </tr>
        <tr
          *ngFor="let row of data.rows; let i = index"
          class="rowTr"
          (click)="clickRow(i)"
        >
          <td
            ngClass="cell {{ format(col) }}"
            *ngFor="let col of row"
          >
            <span *ngIf="col !== 'icon'">{{ col }}</span>
            <img
              *ngIf="col === 'icon'"
              src="{{ getIcon('pdf') }}"
              class="image"
            />
          </td>
        </tr>
        <tfoot *ngIf="data.totale">
          <th
            class="cell right totale"
            *ngFor="let col of data.header"
          >
            <strong *ngIf="data.totale.col === col">{{
              data.totale.value
            }}</strong>
          </th>
        </tfoot>
        <tfoot *ngIf="data.rowTotale">
          <th
            ngClass="cell {{ format(col) }}"
            *ngFor="let col of data.rowTotale"
          >
            <strong>{{ col }}</strong>
          </th>
        </tfoot>
      </table>

      <div
        class="cell center"
        *ngIf="data.rows.length === 0"
      >
        Nessun dato
      </div>
    </div>
  </div>
</div>
