import { DomSanitizer } from '@angular/platform-browser';
import { BookService } from 'src/app/services/book.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'app-images-list',
  templateUrl: './images-list.component.html',
  styleUrls: ['./images-list.component.scss'],
})
export class ImagesListComponent implements OnInit {
  //images = [];
  imgURL: any;
  last: any;
  loading = false;
  @Output() changeImage = new EventEmitter<Object>();
  @Input() max: number;
  @Input() title = '';
  @Input() insert: boolean = false;
  @Input() images = [];
  @Input() isbn: string;

  /**@TODO da sistemare togliendo flag */
  @Input() bookForm: boolean = false;
  @Output() existingImages = new EventEmitter<Object>();

  imageP: any;
  width: number = null;
  height: number = null;
  allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  constructor(
    private bookservice: BookService,
    private sanitizer: DomSanitizer,
    private toastservice: ToastrService
  ) {}

  ngOnInit(): void {
    this.getImages();
  }

  checkimage(l: number, h: number): boolean {
    const shortSide = l < h ? l : h;
    const longSide = l > h ? l : h;
    if (longSide >= 1000 && shortSide >= 500) {
      return true;
    }
    return false;
  }

  addImage(event) {
    if (
      !_.includes(
        this.allowed_types,
        event.target.files[0].type
      )
    ) {
      this.toastservice.error(`Immagine non valida`);
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      let img = new Image();
      this.imgURL = reader.result;
      img.src = this.imgURL;
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;

        const dimension = this.checkimage(
          this.width,
          this.height
        );
        if (dimension) {
          if (this.images.indexOf(this.imgURL) < 0) {
            this.images.push(this.imgURL);
            this.changeImage.emit(event.target.files[0]);
            this.last = event.target.files[0];
          } else {
            this.toastservice.error(
              'Non puoi caricare la stessa immagine più volte',
              'Immagine duplicata'
            );
          }
        } else {
          this.toastservice.error(
            `L'immagine deve avere almeno un lato maggiore o uguale di 1000px e il lato minore almeno di 500px.`
          );
        }
        event.target.value = null;
      };
    };
  }

  removeImage() {
    this.images.pop();
    this.changeImage.emit(0);
  }

  getImages() {
    if (this.isbn) {
      const request = this.bookservice.viewAdditionalImages(
        this.isbn
      );
      this.loading = true;
      fetch(request, { cache: 'reload' }).then(
        (response) => {
          if (response.status != 404) {
            response.json().then((json) => {
              json.data.images.forEach((image) => {
                //this.images.push(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL('data:image/jpeg;base64,'+image)));
                this.images.push(
                  'data:image/jpeg;base64,' + image
                );
                //const blob = new Blob([image],{type: 'image/jpeg'});
                var block =
                  'data:image/jpeg;base64,' +
                  image.split(';');
                //var contentType = block[0].split(":")[1];
                var blob = this.b64toBlob(
                  image,
                  'image/png'
                );

                //blob che verra' caricato
                /*  this.bookForm
                  ? this.existingImages.emit(blob)
                  : this.changeImage.emit(blob); */
                this.existingImages.emit(blob);
              });
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        }
      );
    }
  }
  //converto il base 64 in blob
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (
      var offset = 0;
      offset < byteCharacters.length;
      offset += sliceSize
    ) {
      var slice = byteCharacters.slice(
        offset,
        offset + sliceSize
      );

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
