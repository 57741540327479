<mat-card class="py-1" *ngIf="!this.selectedBook && links.length === 0">
  <app-ricerca (searched)="getSearchType($event)" [ricerca]="ricerca" (resetSearch)="resetForm($event)"
    (sendSubmit)="submitButton()" [singleSearch]="false"></app-ricerca>
</mat-card>
<mat-card class="py-1 mt-2" *ngIf="!this.selectedBook && links.length > 0">
  <!-- <app-breadcrumps [links]="links" (clicked)="catalogo()"></app-breadcrumps> -->
</mat-card>
<div *ngIf="loader" align="center" class="loader">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<mat-card class="card" *ngIf="booksPage.length === 0 && !loader">
  <mat-icon style="vertical-align: middle">info</mat-icon>
  <span> Effettua una ricerca per visualizzare il catalogo</span>
</mat-card>
<mat-card class="card" *ngIf="booksMeta['total'] === 0 && !loader">
  <mat-icon style="vertical-align: middle">error</mat-icon>
  <span> La ricerca non ha prodotto risultati</span>
</mat-card>
<div *ngIf="booksPage" class="tp-wrapper">
  <div class="container-fluid" *ngIf="this.selectedBook != null">
    <mat-card class="book">
      <div align="right" *ngIf="option === 'view'">
        <button mat-raised-button [ngClass]="user.profile" (click)="toggle(null)" color="primary">
          Chiudi Scheda
        </button>
        <!-- <button mat-raised-button (click)="modifica()" color="warn">Modifica</button> -->
      </div>
      <div class="row">
        <div class="col-12">
          <app-book *ngIf="option === 'view'" [code]="this.selectedBook" onlyCda="1"></app-book>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="container-fluid" *ngIf="!this.selectedBook && !loader">
    <mat-card class="book" *ngFor="let book of booksPage">
      <div class="row" [id]="book.attributes.cod">
        <div class="col-2">
          <app-image-view [ean]="book.attributes.ean" [type]="'mini'"></app-image-view>
        </div>
        <div class="col-10">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-10">
                <h2>{{ book.attributes.titolo | uppercase }}</h2>
                <h3>[ISBN]: {{ book.attributes.ean }}</h3>
              </div>
              <div class="col-2" style="display: flex; justify-content: flex-end" *ngIf="user.permessi?.ordini">
                <app-order-quantity [qta]="book.attributes.qta | number: '0.0-0'" [codice]="book.attributes.ean"
                  (editQta)="setQta($event)"></app-order-quantity>
              </div>
            </div>
          </div>

          <p>
            Editore :
            <button [ngClass]="user.profile + '-anteprima'" mat-button color="accent"
              (click)="searchFromBook('editore', book.attributes.cod_editore)">
              {{ book.attributes.editore | uppercase }}
            </button>
            Autore :
            <button [ngClass]="user.profile + '-anteprima'" mat-button color="accent"
              (click)="searchFromBook('autore', book.attributes.cod_autore_1)">
              {{ book.attributes.autore | uppercase }}
            </button>
            Collana
            <button [ngClass]="user.profile + '-anteprima'" mat-button color="accent"
              (click)="searchFromBook('collana', book.attributes.cod_collana)">
              {{ book.attributes.collana }}
            </button>
            <span *ngIf="book.attributes.cod_argomento_1 > 0">
              Argomento:
              <button [ngClass]="user.profile + '-anteprima'" mat-button color="accent" (click)="
                  searchFromBook('argomento', book.attributes.cod_argomento_1)
                ">
                {{ book.attributes.argomento }}
              </button>
            </span>

            <span *ngIf="book.attributes.prezzo && user.profile === 'libreria'">
              Prezzo:
              <button mat-button [ngClass]="user.profile + '-anteprima'" (click)="searchFromBook('collana', book.attributes.cod_collana)">
                {{ book.attributes.prezzo | currency : "EUR" }}
              </button>
            </span>
          </p>
          <div class="row">
            <div class="col-2">
               Data di commercializzazione :
              {{ formatData(book.attributes.data_pubblicazione) }}
            </div>
            <div class="col-2">Categoria : {{ book.attributes.categoria }}</div>
            <div class="col-2">
              Stato : {{ getStato(book.attributes.stato) }}
            </div>
            <div class="col-2">
              <b>
                Disponibilità : {{ book.attributes.disponibilita | integer }}
              </b>
            </div>
          </div>
          <div align="right">
            <button [ngClass]="user.profile" mat-raised-button color="accent" id=""
              (click)="toggle(book.attributes.cod)">
              Scheda Libro
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<mat-paginator *ngIf="!loader && books.length > 0" [length]="booksMeta['total']" [pageSize]="20" [pageIndex]="page - 1"
  (page)="changePage($event)" [showFirstLastButtons]="true">
</mat-paginator>