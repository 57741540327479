import { description } from 'src/app/models/books.model';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BookService } from 'src/app/services/book.service';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autore',
  templateUrl: './autore.component.html',
  styleUrls: ['./autore.component.scss'],
})
export class AutoreComponent implements OnDestroy {
  @Input() itemsLabel: string = '';
  @Input() newItemLabel: string = '';
  @Input() maxItems = 5;
  @Input() items: description[] = [];
  @Input() addElementEnabled = false;
  @Output() selectedItems = new EventEmitter<
    Array<description>
  >();
  @Output() addElement = new EventEmitter<boolean>();
  @Input() disabled = false;
  visible = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  itemCtrl = new FormControl();
  filtereditems: Array<description>;
  subscription: Subscription = null;

  loading = false;
  @ViewChild('itemInput')
  itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  constructor(private apiservice: BookService) {
    this.itemCtrl.valueChanges.subscribe((data) => {
      if (data && data.length >= 3 && !this.loading) {
        this.loading = true;
        this.subscription = this.apiservice
          .autori(data)
          .subscribe((autori: Array<description>) => {
            this.filtereditems = autori;
            this.loading = false;
          });
      }
    });
  }

  remove(item: description): void {
    const index = _.findIndex(this.items, item);
    this.items.splice(index, 1);
    this.selectedItems.emit(this.items);
    if (this.items.length < this.maxItems) {
      this.disabled = false;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const SelectedElement = _.filter(this.filtereditems, {
      cod: event.option.value,
    });
    if (this.items.length < this.maxItems) {
      this.items.push(SelectedElement[0]);
      this.itemInput.nativeElement.value = '';
      this.selectedItems.emit(this.items);
    }
    this.itemCtrl.setValue(null);
    this.filtereditems = [];
  }

  add() {
    this.addElement.emit(true);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
