import {
  DDT,
  ddtCollection,
} from './../../models/document.model';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentService } from 'src/app/services/document.service';
import { MatSort } from '@angular/material/sort';
import { formatCurrency } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DisplayTableComponent } from 'src/app/components/display-table/display-table.component';
import { Utilities } from 'src/app/utilities/utilities.class';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-elenco-ddt',
  templateUrl: './elenco-ddt.component.html',
  styleUrls: ['./elenco-ddt.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ElencoDdtComponent
  implements OnInit, OnDestroy
{
  loader = false;
  rowsLoader = false;
  ricerca = '';
  public page = 1;
  righe: ddtCollection;
  start = null;
  stop = null;
  @ViewChild(MatSort) sort: MatSort;
  defaultSort = 'data_ddt';
  defaultSortDir = 'desc';
  displayedColumns: string[] = [
    'nr_ddt',
    'causale',
    'data_ddt',
    'totale',
    'nr_fattura',
    'data_fattura',
    'totale_fattura',
    'corriere',
    'ddt_file',
  ];
  columnsName: string[] = [
    'Nr.DDT',
    'Causale',
    'Data',
    'Totale',
    'Nr.Fattura',
    'Data Fattura',
    'Totale Fattura',
    'Corriere',
    '',
  ];
  dataSource;
  expandedElement: any | null;
  initData = null;
  dataExport = [];
  subscriptions: Subscription[] = [];
  constructor(
    private documentservice: DocumentService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const start = moment().subtract(3, 'months');
    const stop = moment();
    this.start = new FormControl(start.toDate());
    this.stop = new FormControl(stop.toDate());
    this.getData();
  }

  public getData() {
    let filters = [];
    const filter = {
      name: 'search',
      value: this.ricerca,
    };
    if (this.ricerca !== '') {
      filters.push(filter);
    }
    const orderby = null;
    const startDate = moment(this.start.value).format(
      'YYYYMMDD'
    );
    const dtaStop = moment(this.stop.value).format(
      'YYYYMMDD'
    );
    this.loader = true;
    const sub = this.documentservice
      .getDDTCollection(startDate, dtaStop, filters)
      .subscribe((data: ddtCollection) => {
        const keyToCast = ['totale', 'totale_fattura'];
        this.dataSource = new MatTableDataSource(
          this.buildDataSource(
            Utilities.castToNumber(
              data.data.filter(
                (ddt) =>
                  ddt.nr_causale !== '30' &&
                  ddt.nr_causale !== '31'
              ),
              keyToCast
            )
          )
        );
        this.dataExport = this.buildDataExport(data.data);
        this.loader = false;
      });

    this.subscriptions.push(sub);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement)
      .value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public addEvent() {
    this.getData();
  }

  total(columnName) {
    const totals = ['totale', 'totale_fattura'];
    if (totals.indexOf(columnName) > -1) {
      return formatCurrency(
        _.sumBy(this.dataSource.data, function (o) {
          return Number(o[columnName]);
        }),
        'IT',
        ''
      );
    }
  }

  openRighe(element: DDT) {
    this.rowsLoader = true;
    const sub = this.documentservice
      .getRigheDDT(element.ddt_id)
      .subscribe((data: any) => {
        const displayedColumns: string[] = [
          'nr_riga',
          'stato_articolo',
          'ean',
          'titolo',
          'editore',
          'qta',
          'prezzo',
          'sconto_base',
          'sc1',
          'sc2',
          'sc3',
          'sconto',
          'totale_riga',
        ];
        const columnsName: string[] = [
          'Nr.Riga',
          'Stato',
          'EAN',
          'Titolo',
          'Editore',
          'Qta',
          'Prezzo',
          'sb',
          'sc1',
          'sc2',
          'sc3',
          'Sconto %',
          'Totale Riga',
        ];
        const dialogRef = this.dialog.open(
          DisplayTableComponent,
          {
            data: {
              data: data.data,
              displayNames: columnsName,
              fieldNames: displayedColumns,
              title:
                'Specifica d.d.t nr. ' + element.nr_ddt,
              numbers: ['prezzo', 'totale_riga'],
              totali: ['totale_riga', 'qta'],
            },
          }
        );
        this.rowsLoader = false;
      });

    this.subscriptions.push(sub);
  }

  buildDataSource(collection: any) {
    let dataSource = [];
    collection.forEach((item) => {
      dataSource.push(item);
    });
    return _.orderBy(
      dataSource,
      [this.defaultSort],
      [this.defaultSortDir]
    );
  }

  buildDataExport(collection: any) {
    let dataExport = [];
    let row = {};
    collection.forEach((element) => {
      this.displayedColumns.forEach((column, index) => {
        row[this.columnsName[index]] = element[column];
      });
      dataExport.push(row);
      row = {};
    });
    return dataExport;
  }

  sortData($event) {
    this.defaultSortDir = $event.direction;
    this.defaultSort = $event.active;
    this.dataSource = new MatTableDataSource(
      this.buildDataSource(this.dataSource.data)
    );
  }

  format(field, value) {
    const valute = ['totale', 'totale_fattura'];
    const dates = ['data_fattura', 'data_ddt'];
    const numbers = ['nr_fattura', 'nr_ddt'];

    if (numbers.indexOf(field) > -1) {
      if (Number(value) > 0) {
        return value;
      }
      return;
    }

    if (valute.indexOf(field) > -1) {
      // caso valuta
      if (Number(value) > 0) {
        return formatCurrency(value, 'IT', '');
      }
      return;
    }
    // caso date
    if (dates.indexOf(field) > -1) {
      if (Number(value) >> 20000000) {
        return moment(value, 'YYYYMMDD').format(
          'DD-MM-YYYY'
        );
      }
      return;
    }
    return value;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
