<div class="ep_card" style="display: flex;flex-direction: column;"> 
  <div class="ep_card-header ep_card-header-icon" [ngClass]="'ep_card-header-warning'">
    <div class="ep_card-icon"><i class="material-icons">sim_card_download</i></div>
    <p class="ep_card-category" style="font-size: 20px;display: flex;justify-content: end;margin-top: 1rem;">Download
      Scheda Novita</p>
  </div>
  <div class="ep_card-footer" *ngIf="!loader.pdf">
    <div class="container"> 
    <div class="row">
        <div class="col center">
            <div *ngIf="published">
              I files sono attualmente disponibili per essere scaricati per il giro <strong>{{giro.numero}} / {{giro.anno}}</strong>
              <br>
              <button mat-raised-button  (click)="unpublish()" color="warn">
                <i class="fa fa-icon fa-ban"></i>
                  Non pubblicare
              </button>
            </div>
            <div *ngIf="!published">
              I files non sono disponibili per essere scaricati per il giro <strong>{{giro.numero}} / {{giro.anno}}</strong>
              <br>
              <button mat-raised-button  (click)="publish()" color="primary">
                <i class="fa fa-icon fa-share-square "></i>
                 Pubblica
              </button>
            </div>
            <hr>
        </div>
    </div>
    <div class="row">
      <div class="col-4 center">
        <button mat-raised-button class="download-option" (click)="downloadSchede()">
          <i class="fa fa-icon fa-download"></i>
          &nbsp;Scarica PDEFFONE
        </button>
      </div>
      <div class="col-4 center">
        <button mat-raised-button class="download-option" (click)="rebuild()">
          <i class="fa fa-icon fa-file-pdf-o"></i> &nbsp;Rigenera PDFEFFONE
        </button>
      </div>

      <div class="col-4 center">
        <button mat-raised-button class="download-option" (click)="pdfList()">
          <i class="fa fa-icon fa-file-pdf-o"></i> &nbsp;Lista Cedole App
        </button>
      </div>

    </div>
  </div>
  </div>
  <div class="ep_card-footer" *ngIf="loader.pdf">
    <div align="center">
      Download in corso , ti preghiamo di aspettare, potrebbe volerci qualche
      minuto.
    </div>
    <mat-spinner [diameter]="35" class="mx-auto"></mat-spinner>
  </div>
</div>