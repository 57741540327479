import { ToastrService } from "ngx-toastr";
import { BookService } from "./../../services/book.service";
import { Wbook } from "src/app/models/wbook";
import { LocalStorageService } from "src/app/services/storage.service";
import { Giro } from "./../../models/giro.model";
import { eventModel, MessageService } from "./../../services/message.service";
import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { RistampaDialogComponent } from "src/app/components/ristampa-dialog/ristampa-dialog.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-lanci",
  templateUrl: "./lanci.component.html",
  styleUrls: ["./lanci.component.scss"],
})
export class LanciComponent implements OnInit, OnDestroy {
  @Input() giro: Giro;
  alertClass = "";
  alertClassW = "warning";
  selectedBook = null;
  action = "list";
  bookRistampa = null;
  firstGiro = null;
  isNovita = false;
  subscription:Subscription = null;
  constructor(
    private messageservice: MessageService,
    private storage: LocalStorageService,
    private bookservice: BookService,
    private toastservice: ToastrService,
    private dialog: MatDialog
  ) {
    this.firstGiro = this.storage.get("firstGiro");
    const i = this.storage.get("ristampaBook");
    if (i !== undefined) {
      this.subscription = this.bookservice.setRistampa(i).subscribe(
        (data: any) => {
          const book = new Wbook();
          book.setValues(data);
          this.bookRistampa = book;
          this.storage.remove("ristampaBook");
          this.action = "ristampa";
          // this.messageservice.setEvent({type: 'menu', event: { link: 'novita', action: 'ristampa', book : book}});
        },
        (error) => {
          console.error(error.error.return);
          if (error.status === 412) {
            const dialogRef = this.dialog.open(RistampaDialogComponent, {
              data: error.error.return,
              height: "auto",
              width: "auto",
            });
            dialogRef
              .afterClosed()
              .toPromise()
              .then((data) => {
                this.storage.remove("ristampaBook");
              });

            // this.storage.remove('ristampaBook');
            // this.toastservice.error('Contatta CDA per completare la scheda del libro, altrimenti non sarai in grado di effettuare una ristampa', 'Anagrafica libro incompleta')
          }
        }
      );
    }
  }

  ngOnInit(): void {
    const actionStorage = this.storage.get("action");
    if (actionStorage !== undefined) {
      this.storage.remove("action");
      this.action = "insert";
    }
  }

  showBooks(element: string) {
    if (element === "novita") {
      this.action = "insert";
    } else {
      this.action = "list";
      this.selectedBook = null;
    }
  }

  editBook(element) {
  
    
    this.selectedBook = element.book;
    this.isNovita = element.novita;
    this.action = "edit";
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
