import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OpenHolidaysService } from 'src/app/services/open-holidays.service';
import { LocalStorageService } from 'src/app/services/storage.service';

export interface Holiday {
  id: string;
  startDate: string;
  endDate: string;
  type: string;
  name: Array<{ language: string; text: string }>;
  regionalScope: string;
  temporalScope: string;
  nationwide: boolean;
}

@Component({
  selector: 'app-data-commercializzazione-picker',
  templateUrl:
    './data-commercializzazione-picker.component.html',
  styleUrls: [
    './data-commercializzazione-picker.component.scss',
  ],
})
export class DataCommercializzazionePickerComponent
  implements OnInit
{
  @Input() viewOnly: boolean = false;
  @Input() dataPubblicazione;

  @Output() cambioDataEmit = new EventEmitter();

  minDate; ///= moment().add(15, 'days').format('YYYY-MM-DD');
  loading: boolean = false;
  holidayDateFilter;

  constructor(
    private holidaysApi: OpenHolidaysService,
    private storage: LocalStorageService
  ) {
    const dataScadenza = storage.get('giro').data_fine_prenotazione;

    /** se il primo giorno feriale disponibile dopo la chiusura degli agenti meno i 14 giorni che servono per la consegna in CDA
     * sono prima di oggi allora tutto ok la data minima è il primo giorno lavorativo dopo la chiusura del giro degli agenti
     *
     * in caso contrario il giorno di riferimento diventa oggi a cui sommo i 14 giorni per la consegna in CDA.
     */
    if (
      this.getNextWeekday(dataScadenza)
        .subtract(14, 'days')
        .isAfter(moment())
    ) {
      this.minDate = moment(dataScadenza)
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      this.minDate = moment()
        .add(14, 'days')
        .format('YYYY-MM-DD');
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.holidaysApi
      .getHolidays(
        moment().format('YYYY-MM-DD'),
        moment().add(2, 'years').format('YYYY-MM-DD')
      )
      .subscribe({
        next: (data: Holiday[]) => {
          this.holidayDateFilter = (
            date: Date
          ): boolean => {
            // Controllo che non sia una festa, come Natale o Pasqua
            let isHolidayDate = data.some((holiday) => {
              return moment(date).isBetween(
                holiday.startDate,
                holiday.endDate,
                null,
                '[]'
              );
            });

            // Se non è festivo né weekend, è feriale
            return !(
              moment(date).isoWeekday() === 6 ||
              moment(date).isoWeekday() === 7 ||
              isHolidayDate
            );
          };
        },
        error: (err) => console.error(err),
        complete: () => (this.loading = false),
      });
  }

  /**
   * Mi prendo il primo giorno feriale dopo la data in input
   * @param date data di cui voglio calcolare il giorno
   * @returns il primo giorno lavorativo
   */
  getNextWeekday(date) {
    let nextDay = moment(date).add(1, 'day');
    while (
      nextDay.isoWeekday() === 6 ||
      nextDay.isoWeekday() === 7
    ) {
      nextDay.add(1, 'day');
    }
    return nextDay;
  }
}
