<div *ngIf="!book">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
</div>
<div
  class="row"
  *ngIf="book"
>
  <div
    class="col-3"
    *ngIf="book.ean"
  >
    <app-image
      title="Copertina Libro"
      (changeFile)="setCopertina($event)"
      [picture]="immagineCopertina"
    ></app-image>
    <br />

    <app-images-list
      [title]="
        'Immagini aggiuntive ' + this.images.length + '/6'
      "
      [isbn]="book.ean"
      (existingImages)="setAdditionalImages($event)"
      (changeImage)="setAdditionalImages($event)"
      max="6"
    ></app-images-list>

    <br />

    <app-pdf-uploader
      title="Scheda Novità *"
      [pdferror]="pdffilename"
      [loading]="loadingScheda"
      (changeFile)="setPdf($event)"
      [pdf]="novita"
      (download)="downloadPdf()"
    ></app-pdf-uploader>
  </div>

  <div class="col-9">
    <!-- controllo presenza "titolo esteso" (book.test) -->
    <h2>
      {{
        (book.test ? book.test : book.titolo) | uppercase
      }}
    </h2>
    <h3>[ISBN]: {{ book.ean }}</h3>

    <mat-card class="book-section">
      <h3>Abstract</h3>
      <angular-editor
        [(ngModel)]="book.abstract"
        [config]="config"
        name="recensione"
      >
      </angular-editor>
    </mat-card>
    <mat-card class="book-section">
      <h3>Note</h3>
      <angular-editor
        [(ngModel)]="book.note"
        [config]="config"
        #note
        name="note"
      ></angular-editor>
    </mat-card>
  </div>
</div>
