import { SessionStorageService } from './../../services/session.service';
import {
  iUser,
  Partizioni,
} from './../../models/user.model';
import { MessageService } from 'src/app/services/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Giro } from './../../models/giro.model';
import { NuovoAutoreComponent } from './../nuovo-autore/nuovo-autore.component';
import { description } from 'src/app/models/books.model';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './../../services/storage.service';
import { BookService } from './../../services/book.service';
import { Wbook, WBookEntity } from './../../models/wbook';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER, T } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { environment } from 'src/environments/environment';
import { merge, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  map,
  startWith,
  tap,
} from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { Router } from '@angular/router';

export interface simpleResponce {
  success: boolean;
  filename: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.scss'],
})
export class BookFormComponent
  implements OnInit, OnDestroy
{
  @Input() book: Wbook = new Wbook();
  @Input() oid: number = 0;
  @Input() ristampa: boolean = false;
  @Input() viewOnly: boolean = false;
  @Input() consegnatoMagazzino: boolean = false;

  dataConsegnaMagazzino;
  charTag = true;
  loadingCopertina = false;
  loadingScheda = false;
  loading = true;
  error = false;
  disabilitaModifiche = false;
  immagineCopertina = null;
  images = [];
  pdffilename = '';
  novita = null;
  collane: Array<description>;
  autoriSelected: Array<description> = [];
  collaboratoriSelected: Array<any> = [];
  generi: Array<description>;
  categorie: Array<description>;
  argomenti: Array<description>;
  lingue: Array<description>;
  rilegature: Array<description>;
  mesiUscita: Array<description> = [];
  loadingAutori = false;
  loadingAdditionalImages = false;
  giri: Giro[];
  defaultCodiciAutori = [];
  autore;
  steps = 0;
  nsteps = 6;
  errors = {
    richiestastampa: false,
  };
  annoEdizione = moment().format('Y');
  meseEdizione;
  aliquotaIva: description;
  tags = [];
  defaultAutori: Array<description>;
  defaultCollaboratori: any[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  startImages = true; // uso questa per non andare a toccare il componente princiaple di list images
  erroPatternObj = [];

  tabellaIva = [
    { cod: '101', iva: 74 },
    { cod: '102', iva: 74 },
    { cod: '103', iva: 22 },
    { cod: '104', iva: 22 },
    { cod: '105', iva: 22 },
    { cod: '106', iva: 22 },
    { cod: '107', iva: 22 },
    { cod: '108', iva: 22 },
    { cod: '109', iva: 22 },
    { cod: '110', iva: 22 },
    { cod: '111', iva: 22 },
    { cod: '112', iva: 22 },
    { cod: '113', iva: 22 },
    { cod: '114', iva: 22 },
    { cod: '115', iva: 22 },
    { cod: '116', iva: 22 },
    { cod: '117', iva: 22 },
    { cod: '118', iva: 22 },
    { cod: '140', iva: 22 },
    { cod: '150', iva: 22 },
    { cod: '154', iva: 5 },
    { cod: '180', iva: 74 },
  ];

  @Output() OnClickNovita = new EventEmitter<boolean>();
  @Output() OnBookInserted = new EventEmitter<number>();

  private copertina = new FormData();
  private schedaNovita = new FormData();
  private additionalImages = new FormData();
  isbn: string = null;

  /**@todo spostare in un servizio o scrivere un file json tipo cod: descrizione */
  public mesi = [
    { cod: 1, name: 'gennaio' },
    { cod: 2, name: 'febbraio' },
    { cod: 3, name: 'marzo' },
    { cod: 4, name: 'aprile' },
    { cod: 5, name: 'maggio' },
    { cod: 6, name: 'giugno' },
    { cod: 7, name: 'luglio' },
    { cod: 8, name: 'agosto' },
    { cod: 9, name: 'settembre' },
    { cod: 10, name: 'ottobre' },
    { cod: 11, name: 'novembre' },
    { cod: 12, name: 'dicembre' },
  ];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'bold',
        'customClasses',
        'insertImage',
        'insertVideo',
        'link',
        'unlink',
        'toggleEditorMode',
        'clearFormatting',
        'subscript',
        'superscript',
        'aepicker',
      ],
    ],
  };
  disabledConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };
  storageData: Giro = null;
  public formTitle = '';
  firstGiro: Giro = null;
  action = null;
  isbnOptions: string[] = [];
  filteredOptions: Observable<string[]>;
  isbnControl = new FormControl('');
  editFull = true;
  modalitaStampaData = null;
  giroSelezionato = null;
  usr: iUser;
  loadingGiro = false;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private bookservice: BookService,
    private toast: ToastrService,
    private domSanitizer: DomSanitizer,
    private messageservice: MessageService
  ) {
    this.usr = this.session.get('usr');
    if (this.usr.partizioni) {
      this.usr.partizioni.forEach((partizione) => {
        this.isbnOptions.push(partizione.EP_PARTIZIONE);
      });
    }
    this.filteredOptions =
      this.isbnControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );

    //aggiungo il validatore al FormControl dell'isbn
    this.isbnControl.setValidators(
      this.isbnFieldValidator()
    );
    this.isbnControl.updateValueAndValidity();
  }

  ngOnInit(): void {

    // messaggio se: vien fatto il refresh, viene chiusa la tab o viene chiuso il browser
    window.addEventListener('beforeunload', (event) => {
      const confirmationMessage =
        'Sei sicuro di voler lasciare questa pagina?';
      event.preventDefault();
      return confirmationMessage;
    });

    this.loading = true;
    if (this.usr.profile === 'editore') {
      this.getCollane();
    }
    this.getCategorie();
    this.getGeneri();
    this.getLingue();
    this.getRilegature();
    this.getArgomenti();
    this.firstGiro = this.storage.get('firstGiro');
    this.storageData = this.storage.get('giro');

    if (this.oid) {
      this.loadBook(this.oid);
      this.action = 'edit';
      this.formTitle = 'Modifica novità';
    } else {
      this.book.nuGiro = Number(this.storageData.numero);
      this.book.annoGiro = Number(this.storageData.anno);
      this.action = 'insert';
      this.formTitle = 'Inserimento novità';
      this.modalitaStampaData = new Date(
        this.storageData.data_scadenza
      );
    }

    if (this.ristampa) {
      /*  if(this.usr.profile === 'admin'){
        this.storageData = this.storage.get('selectedGiro');
      } */
      this.book.nuGiro = Number(this.storageData.numero);
      this.book.annoGiro = Number(this.storageData.anno);
      this.action = 'insert';
      this.formTitle = 'Ristampa';
      this.makeDefaultAuthors();
      this.disabilitaModifiche = true;
      this.getTabellaIva(false);
      this.loadCopertina();
      this.loadSchedaNovita();
    }
    if (this.usr.profile === 'admin') {
      this.book.editore = this.storage.get(
        'selectedEditore'
      );
      this.storage.remove('selectedEditore');
      this.disabilitaModifiche = false;
      this.getPartizioni();
      if (this.book.editore) {
        this.getCollaneAdmin(this.book.editore);
      }
    }
    if (this.book.upload > 0) {
      // esiste la copertina del libro.
      const request = this.bookservice.imageRequest(
        this.book.isbn.toString()
      );
      fetch(request, { cache: 'reload' })
        .then((response) => response.blob())
        .then((blob) => {
          this.immagineCopertina =
            URL.createObjectURL(blob);
        });
    }

    this.loading = false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.isbnOptions.filter(
      (option) =>
        option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  makeDefaultAuthors() {
    for (let i = 1; i < 7; i++) {
      if (Number(this.book['cod_autore_' + i]) !== 0) {
        this.defaultCodiciAutori.push(
          Number(this.book['cod_autore_' + i])
        );
      }
    }
    const sub = this.bookservice
      .getAutoriInfo(this.defaultCodiciAutori)
      .subscribe((data: Array<description>) => {
        this.autoriSelected = data;
      });

    this.subscriptions.push(sub);
  }

  /**
   * prendo tutti i giri
   * @param anno
   */
  getGiri(anno = 0) {
    this.loadingGiro = true;
    const sub = this.bookservice
      .getGiriByYear(anno)
      .subscribe((giri: Array<Giro>) => {
        this.giri = giri;
        const data: Array<description> = [];
        this.giri = this.giri.map((item: Giro) => ({
          ...item,
          cod: `${item.anno}.${item.numero}`,
        })); /* .filter(
          (el) =>
            moment(el.data_distribuzione).isAfter(moment())
        ) */
        this.setGiroDefault();
      });

    this.subscriptions.push(sub);
  }

  /**
   * Mi setto il giro corretto del libro
   */
  setGiroDefault() {
    this.giroSelezionato = this.giri.filter((giro) => {
      const nuGiro =
        this.book.nuGiro.toString().length < 2
          ? `0${this.book.nuGiro}`
          : this.book.nuGiro.toString();

      return (
        nuGiro === giro.numero &&
        this.book.annoGiro.toString() === giro.anno
      );
    })[0];

    this.loadingGiro = false;
  }

  changeGiro(event: MatSelect) {
    const giro = event.value.split('.');
    this.book.nuGiro = parseInt(giro[1], 10);
    this.book.annoGiro = parseInt(giro[0], 10);
  }

  loadBook(oid) {
    const sub = this.bookservice
      .getEntityNovita(oid)
      .subscribe((data: WBookEntity) => {
        const b = new Wbook();
        b.setValues(data);
        this.setCollaboratoriInput(data.collaboratori[0]);
        this.book = b;

        if (this.usr.profile === 'admin') {
          this.getCollaneAdmin(this.book.editore);
        }
        this.makeDefaultAuthors();
        const t = this.book.tags.split(',');
        t.forEach((item) => {
          if (item.replace(' ', '').length > 0)
            this.tags.push(item);
        });
        const interval = setInterval(() => {
          if (this.steps === this.nsteps) {
            this.getTabellaIva(false);
            clearInterval(interval);
          }
        }, 100);
        if (this.usr.profile === 'editore') {
          const giro = Number(
            this.firstGiro.anno +
              (Number(this.firstGiro.numero) - 1)
          );
          const giroLibro = Number(
            this.book.annoGiro + Number(this.book.nuGiro)
          );
          if (giroLibro === giro || giroLibro > giro) {
            this.disabilitaModifiche = false;
          } else {
            this.disabilitaModifiche = true;
          }
        }
        //** controllo se si tratta di una ristampa */
        if (
          this.book.idRistampa &&
          this.book.idRistampa.length > 1
        ) {
          this.disabilitaModifiche = true;
        }
        if (this.book.upload > 0) {
          // esiste la copertina del libro.
          this.loadCopertina();
        }
        this.loadSchedaNovita();

        // quando ho caricato il libro mi carico anche i giri
        this.getGiri();

        this.dataConsegnaMagazzino = moment(
          this.book.datapubblicazione
        )
          .subtract(14, 'days')
          .format('DD-MM-YYYY');
      });
    this.subscriptions.push(sub);
  }

  setCollaboratoriInput(collaboratori) {
    const collab = {
      CUR: 'curatore',
      ILL: 'illustratore',
      TRA: 'traduttore',
      PRE: 'prefazione',
    };

    for (let i = 1; i <= 5; i++) {
      const collabObj = {
        cod: collaboratori[`cod_ruolo${i}`],
        descrizione: collaboratori[`name${i}`],
        type: collab[collaboratori[`ruolo${i}`]],
      };

      this.collaboratoriSelected.push(collabObj);
    }

    this.collaboratoriSelected =
      this.collaboratoriSelected.filter(
        (collab) => collab.descrizione
      );
  }

  /**
   * loadSchedaNovita
   */
  loadSchedaNovita() {
    this.loadingScheda = true;
    const request = this.bookservice.schedaNovitaRequest(
      this.book.isbn.toString()
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          this.novita =
            this.domSanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(blob)
            );
          this.setPdf(blob, 'novita', true);
          this.loadingScheda = false;
        });
      } else {
        this.loadingScheda = false;
      }
    });
  }

  loadCopertina() {
    this.loadingCopertina = true;
    const request = this.bookservice.imageRequest(
      this.book.isbn.toString()
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status !== 404) {
        response.blob().then((blob) => {
          this.immagineCopertina =
            URL.createObjectURL(blob);
          this.setCopertina(blob, false);
          this.loadingCopertina = false;
        });
      } else {
        this.immagineCopertina = null;
        this.loadingCopertina = false;
      }
    });
  }

  downloadPdf() {
    const request = this.bookservice.schedaNovitaRequest(
      this.book.isbn.toString()
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${this.book.isbn}.pdf`;
          a.click();
        });
      }
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.checkTags(value)) {
      if ((value || '').trim()) {
        this.tags.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.book.tags = this.tags.toString();
    }
  }

  /**
   * Controllo se i tag, con l'aggiunta di quello che sto cercando di inserire, non superano i 100 caratteri
   * @returns
   */
  checkTags(insertValue: string): boolean {
    this.charTag =
      this.tags.toString().length + insertValue.length <=
      100;

    return this.charTag;
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.book.tags = '';
    let i = [];
    this.tags.forEach((item) => {
      i.push(item.name);
    });
    this.book.tags = i.toString();
  }

  setAutori($event) {
    this.autoriSelected = $event;
    this.book.cod_autore_1 = 0;
    this.book.cod_autore_2 = 0;
    this.book.cod_autore_3 = 0;
    this.book.cod_autore_4 = 0;
    this.book.cod_autore_5 = 0;
    this.autoriSelected.forEach((element, index) => {
      let autoreIndex = index + 1;
      this.book['cod_autore_' + autoreIndex] = element.cod;
    });
  }

  /**
   * funzione che serve a salvare la copertina a BE
   * @param $event immagine che viene passata dal componente
   * @param saveToBE se l'immagine va salvata a BE, così che nel primo caricamento non si faccia una chiamata inutile
   */
  setCopertina($event, saveToBE = true): void {
    if ($event !== null) {
      this.copertina.delete('image');
      this.copertina.append('image', $event);
    } else {
      this.copertina.delete('image');
    }

    /*  if (saveToBE) {
      this.saveCopertina(this.book.isbn);
    } */

    // this.book.sizeScheda = ($event) ? $event.size : 0;
  }

  setPdf($event, tipo: string, check = true): void {
    if ($event !== null) {
      this.schedaNovita.delete('scheda');
      this.schedaNovita.append('scheda', $event);
      this.pdffilename = $event.name;
      if (check) {
        this.loadingScheda = true;
        const sub = this.bookservice
          .checkSchedaNovita(this.schedaNovita)
          .subscribe({
            next: (data: simpleResponce) => {
              if (data.success) {
                this.pdffilename = data.filename;
              } else {
                this.unvalidPdf();
              }
            },
            error: (er) => {
              this.unvalidPdf();
            },
            complete: () => (this.loadingScheda = false),
          });

        this.subscriptions.push(sub);
      }
    } else {
      this.schedaNovita.delete('scheda');
    }
  }

  /**
   * Unvalid pdf action
   */
  private unvalidPdf() {
    this.toast.error(
      'Il pdf che hai caricato non è valido e sarà scartato.'
    );
    this.pdffilename = '';
    this.schedaNovita.delete('scheda');
    this.loadingScheda = false;
  }

  private checkPartizione(partizione) {
    //prendo la lunghezza della partizione
    const l = partizione.length;
    //verifico che isbn inizi con la partizione fornita;
    if (
      Number(this.book.isbn.toString().substring(0, l)) ===
      Number(partizione)
    ) {
      return true;
    }
    return false;
  }

  submit() {
    if (!this.ristampa && this.action === 'insert') {
      if (
        this.book.isbn?.toString().length === 13 &&
        this.checkIsbn(this.book.isbn)
      ) {
        // controllo l'isbn
        const sub = this.bookservice
          .checkIsbn(this.book.isbn)
          .subscribe((resp: any) => {
            if (resp.success === true) {
              this.inserimento();
            } else {
              this.toast.error(
                `Il codice da te inserito risulta già inserito nei nostri archivi, sei pregato di inserire un ISBN diverso per poter continuare con l'inserimento`,
                'Errore ISBN'
              );
            }
          });

        this.subscriptions.push(sub);
      } else {
        this.toast.error(
          `Il codice ISBN ${this.book.isbn.toString()} non è valido`,
          'Codice ISBN non valido'
        );
      }
    } else {
      this.inserimento();
    }
  }

  inserimento() {
    this.error = false;

    // controllo l'isbn ma solo se si tratta di una novita non per le ristampe.

    if (!this.book.richStampa) {
      this.toast.error(
        `Ti prego, facci sapere le opzioni di stampa`,
        'Informazione necessaria'
      );
      this.errors.richiestastampa = true;
      this.error = true;
    }

    if (Number(this.book.numero_pagine) < 1) {
      this.toast.error(
        `Il numero di pagine deve essere maggiore a 0`,
        'Informazione non valida'
      );
      this.error = true;
    }

    if (this.book.base < 5 || this.book.base > 50) {
      this.toast.error(
        `Il campo base deve avere valori compresi tra 5 e 50`,
        'Informazione non valida'
      );
      this.error = true;
    }

    if (this.book.altezza < 8 || this.book.altezza > 60) {
      this.toast.error(
        `Il campo altezza deve avere valori compresi tra 8 e 60`,
        'Informazione non valida'
      );
      this.error = true;
    }

    if (
      this.book.spessore < 0.3 ||
      this.book.spessore > 20
    ) {
      this.toast.error(
        `Il campo spessore deve avere valori compresi tra 0.3 e 20`,
        'Informazione non valida'
      );
      this.error = true;
    }

    if (this.book.peso < 100 || this.book.peso > 3000) {
      this.toast.error(
        `Il campo peso deve avere valori compresi tra 100 e 3000`,
        'Informazione non valida'
      );
      this.error = true;
    }

    if (
      (this.book &&
        this.book.recensione &&
        this.book.recensione.length < 10) ||
      !this.book.recensione
    ) {
      this.toast.error(
        `L'abstract è un campo obbligatorio`,
        'Informazione necessaria'
      );
      this.error = true;
    }

    if (!this.copertina.has('image')) {
      this.toast.error(
        `Almeno un'immagine deve essere caricata`,
        'Informazione necessaria'
      );
      this.error = true;
    }

    if (!this.schedaNovita.has('scheda')) {
      this.toast.error(
        `Il pdf della scheda novità non è stato fornito`,
        'Informazione necessaria'
      );
      this.error = true;
    }

    // controllo la tabella delle partizioni per il codice EAN */
    let result = false;
    if (this.usr.partizioni) {
      this.usr.partizioni.forEach((partizione) => {
        if (result === false) {
          result = this.checkPartizione(
            partizione.EP_PARTIZIONE
          );
        }
      });
    } else {
      result = true;
    }

    const payload = this.book.getPayLoad();
    payload.filename = this.pdffilename;

    if (!payload.NV_VET_AUT_1) {
      this.toast.info(
        `Se l'autore che cerchi non si trova tra quelli in lista, ne puoi aggiungere uno tramite il pulsante <span class="material-icons">person_add_alt_1</span> `,
        'Non trovi un autore?',
        { enableHtml: true }
      );
      this.toast.error(
        "Per continuare con l'insertimento della novità, aggiungi un autore",
        'Nessun Autore selezionato'
      );
      this.error = true;
    }

    if (this.error) {
      return;
    }

    const t = setInterval(() => {
      this.loading = true;
      if (this.error) {
        this.error = false;
        this.loading = false;
        clearInterval(t);
      }

      if (
        !this.loadingCopertina &&
        !this.loadingAdditionalImages
      ) {
        this.loading = false;
        this.error = false;
        clearInterval(t);
        if (!this.book.oid) {
          this.OnClickNovita.emit(true);
        }
      }
    }, 100);

    if (this.action === 'insert') {
      const insertSusbcriptions = merge(
        this.handleOperationWithToast(
          this.bookservice.insertWbook(payload),
          'Salvataggio della novità riuscito',
          `Errore nell'inserimento della novità`
        ),
        this.handleOperationWithToast(
          this.bookservice.insertAdditionalImages(
            this.additionalImages,
            this.book.isbn
          ),
          this.additionalImages.has('images[]')
            ? 'Salvataggio delle immagini aggiuntive riuscito'
            : '',
          this.additionalImages.has('images[]')
            ? `Errore nell'inserimento delle immagini aggiuntive`
            : ''
        ),
        this.handleOperationWithToast(
          this.bookservice.insertCopertina(
            this.copertina,
            this.book.isbn
          ),
          'Copertina inserita con successo',
          `Errore nell'isnerimento della copertina`
        )
      );
      const request = this.bookservice.imageRequest(
        this.book.isbn.toString()
      );

      const sub = insertSusbcriptions.subscribe({
        next: (val) => {
          console.log(val);
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => this.OnClickNovita.emit(true),
      });
    } else {
      const modifySubscriptions = merge(
        this.additionalImages.has('images[]')
          ? this.handleOperationWithToast(
              this.bookservice.insertAdditionalImages(
                this.additionalImages,
                this.book.isbn
              ),
              'Salvataggio delle immagini aggiuntive riuscito',
              `Errore nell'inserimento delle immagini aggiuntive`
            )
          : this.handleOperationWithToast(
              this.bookservice.deleteAdditionalImages(
                this.book.isbn
              ),
              'Eliminazione delle immagini aggiuntive riuscito',
              `Errore nell'eliminazione delle immagini aggiuntive`
            ),
        this.handleOperationWithToast(
          this.bookservice.insertCopertina(
            this.copertina,
            this.book.isbn
          ),
          'Salvataggio della copertina riuscito',
          `Errore nell'inserimento della copertina`
        ),
        this.handleOperationWithToast(
          this.bookservice.saveBook(payload, this.book.oid),
          'Salvataggio della novità riuscito',
          `Errore nell'inserimento della novità`
        )
      );

      let error = false;
      const sub = modifySubscriptions.subscribe({
        next: () => {},
        error: () => {
          error = true;
        },
        complete: () => this.OnClickNovita.emit(true),
      });

      this.subscriptions.push(sub);
    }
  }

  /**
   *
   * @param operation$ Observable che andrò a sotoscrivere
   * @param successMessage messagio in caso di successo
   * @param errorMessage messaggio in caso di errore
   * @returns
   */
  handleOperationWithToast<T>(
    operation$: Observable<T>,
    successMessage: string,
    errorMessage: string
  ): Observable<T> {
    return operation$.pipe(
      tap(() => {
        this.toast.success(successMessage);
      }),
      catchError((err) => {
        this.toast.error(errorMessage);
        return of(null);
      })
    );
  }

  saveCopertina(ean) {
    if (this.copertina.has('image')) {
      this.loadingCopertina = true;
      this.messageservice.setEvent({
        type: 'loadImage',
        event: { ean: this.book.isbn },
      });
      //  this..setEvent({type:'loadImage', event : { ean : this.book.isbn} });
      if (!this.book.oid) {
        const sub = this.bookservice
          .insertCopertina(this.copertina, ean)
          .subscribe(
            (data) => {
              this.toast.info(
                'Immagine copertina inserita con successo'
              );
              this.messageservice.setEvent({
                type: 'reloadImage',
                event: { ean: this.book.isbn },
              });
              this.loadingCopertina = false;
            },
            (error) => {
              this.loadingCopertina = false;
              this.error = true;
              this.toast.error(
                "Si sono verificati degli errori durante il caricamento dell'immagine di copertina"
              );
            }
          );

        this.subscriptions.push(sub);
      }
    }
  }

  async getCollaneAdmin(editore) {
    fetch(
      this.bookservice.getCollectionCached(
        'collane',
        editore
      )
    )
      .then((response) => response.json())
      .then((collane) => {
        this.collane = collane;
        this.steps++;
      });
  }

  async getCollane() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('collane')
    );
    response.json().then((data) => {
      this.collane = data;
      this.steps++;
    });
  }

  async getGeneri() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('generi')
    );
    response.json().then((data) => {
      this.generi = data;
      this.steps++;
    });
  }

  async getCategorie() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('categorie')
    );
    response.json().then((data) => {
      this.categorie = data;
      this.steps++;
    });
  }

  async getArgomenti() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('argomenti')
    );
    response.json().then((data) => {
      this.argomenti = data;
      this.steps++;
    });
  }

  async getRilegature() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('rilegature')
    );
    response.json().then((data) => {
      this.rilegature = data;
    });
    this.steps++;
  }

  async getLingue() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached('lingue')
    );
    response.json().then((data) => {
      this.lingue = data;
      this.steps++;
    });
  }

  getTabellaIva(toast = true) {
    const sub = this.bookservice
      .getTabellaIva()
      .subscribe((iva: Array<description>) => {
        //this.iva = iva;
        const i = _.findIndex(this.tabellaIva, [
          'cod',
          this.book.cod_categoria,
        ]);
        const row = this.tabellaIva[i];

        iva.forEach((item) => {
          if (parseInt(item.cod) === row.iva) {
            this.aliquotaIva = item;
            this.book.cod_iva = parseInt(item.cod);
            if (toast) {
              this.toast.info(
                item.descrizione,
                'Aliquota IVA'
              );
            }
          }
        });
      });

    this.subscriptions.push(sub);
  }

  clickClose() {
    let dialogRef = this.dialog.open(
      GenericDialogComponent,
      {
        width: '400px',
        data: {
          title: 'Attenzione',
          content: `
          Abbandonando ora il form senza salvare perderai tutte le informazioni inserite`,
        },
      }
    );

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.OnClickNovita.emit(true);
      }
    });
  }

  setCollaboratori(event) {
    // controllo se ci sono elementi eliminati
    this.book.collaboratori = event;
  }

  aggiungiAutore(type: string) {
    const dialogRef = this.dialog.open(
      NuovoAutoreComponent,
      {
        data: { nome: '', cognome: '', tipo: type },
        height: 'auto',
        width: 'auto',
      }
    );

    let inserted = false;

    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data) {
          if (
            data &&
            data.nome &&
            data.cognome &&
            !inserted
          ) {
            const sub = this.bookservice
              .insertAutore(data)
              .subscribe((resp: any) => {
                if (resp.inserted) {
                  this.toast.success(
                    `${type === 'autore' ? `L'autore` : 'Il collaboratore'} ${
                      data.nome
                    } ${data.cognome} è stato inserito correttamente`,
                    'Inserimento Autore'
                  );
                } else {
                  this.toast.info(
                    `  ${type === 'autore' ? `L'autore` : 'Il collaboratore'} ${
                      data.nome
                    } ${data.cognome} è già presente in archivio, lo inserisco nella sezione apposita`
                  );
                }

                if (type === 'autore') {
                  this.autoriSelected.push({
                    cod: resp.cod,
                    descrizione: `${this.primaLetterMaiuscola(data.cognome)} ${this.primaLetterMaiuscola(data.nome)}`,
                  });
                  this.setAutori(this.autoriSelected);
                } else {
                  this.collaboratoriSelected.push({
                    cod: resp.cod,
                    descrizione: `${data.cognome} ${data.nome}`,
                    type: 'curatore',
                  });

                  this.setCollaboratori(
                    this.collaboratoriSelected
                  );
                }
              });

            this.subscriptions.push(sub);
          } else {
            this.toast.info(
              `Attenzione, ${type} già presente in archivio`
            );
          }
        }
      });
  }

  primaLetterMaiuscola(str: string): string {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  richiestaStampa(event) {
    this.book.richStampa = event.value;
    this.errors.richiestastampa = false;
  }

  saveAdditionalImages(id) {
    if (this.additionalImages.has('images[]')) {
      this.loadingAdditionalImages = true;
      const sub = this.bookservice
        .insertAdditionalImages(this.additionalImages, id)
        .subscribe(
          (data) => {
            this.loadingAdditionalImages = false;
            this.toast.info(
              'Immagini inserite con successo'
            );
          },
          (error) => {
            this.loadingAdditionalImages = false;
            this.toast.error(
              'Si sono verificati degli errori durante il caricamento delle immagini'
            );
            this.error = true;
          }
        );
      this.subscriptions.push(sub);
    }
  }

  deleteAdditionalImages(id) {
    this.loadingAdditionalImages = true;
    const sub = this.bookservice
      .deleteAdditionalImages(id)
      .subscribe(
        (data) => {
          /* this.status--; */
          this.loadingAdditionalImages = false;
        },
        (error) => {
          this.loadingAdditionalImages = false;
          this.error = true;
        }
      );

    this.subscriptions.push(sub);
  }

  /**
   * salvo le immagini aggiuntive
   * @param $event immagine aggiunta
   */
  setAdditionalImages($event): void {
    // Eliminazione o aggiunta immagine
    if ($event === 0) {
      this.images.pop();
      if (this.images.length === 0) {
        this.additionalImages.delete('images[]');
      }
    } else {
      this.images.push($event);
    }

    this.additionalImages.delete('images[]');

    for (let image of this.images) {
      this.additionalImages.append('images[]', image);
    }
  }

  /**
   * Setto le immagini aggiuntive
   * @param $event immagine da aggiungere
   */
  setImages($event) {
    if ($event === 0) {
      this.images.pop();
      if (this.images.length === 0) {
        this.additionalImages.delete('images[]');
      }
    } else {
      this.images.push($event);
    }

    this.additionalImages.delete('images[]');
    for (let image of this.images) {
      this.additionalImages.append('images[]', image);
    }
  }

  getPartizioni() {
    const sub = this.bookservice
      .partizioni(this.book.editore)
      .subscribe((partizioni: Array<Partizioni>) => {
        partizioni.forEach((partizione) => {
          this.isbnOptions.push(partizione.EP_PARTIZIONE);
        });
      });

    this.subscriptions.push(sub);
  }

  /**controllo se l'isbn inserito e' valido */
  checkIsbn(isbn) {
    if (isbn?.length > 0) {
      const tokens = isbn.split('');
      let sum = 0;
      tokens.forEach((item, index) => {
        let multi = index % 2 ? 3 : 1;
        sum += Number(item) * multi;
      });
      return sum % 10 === 0 ? true : false;
    }
    return false;
  }

  //validatore del campo isbn
  isbnFieldValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (this.checkIsbn(control.value)) {
        const sub = this.bookservice
          .checkIsbn(control.value)
          .subscribe((resp: any) => {
            if (
              !resp.success &&
              this.action !== 'edit' &&
              !this.ristampa
            ) {
              control.setErrors({
                invalidIsbn: 'Isbn già inserito',
              });
            }
          });

        this.subscriptions.push(sub);
      } else {
        return control.value?.length == 13
          ? { invalidIsbn: 'Isbn non valido' }
          : null;
      }
    };
  }

  /**
   * Mi aggiorno la data in cui il prodotto deve essere consegnato in magazzino
   * @param event data inserita in data commercializzazione
   */
  updateDate(event) {
    this.book.datapubblicazione = event;

    this.dataConsegnaMagazzino = moment(event)
      .subtract('14', 'days')
      .format('DD/MM/YYYY');
  }

  /**
   * Quando non c'è l'isbn per l'immaggine faccio vedere questo dialog
   */
  showNoIsbnDialog(){
    const dialog =  this.dialog.open(GenericDialogComponent, {
      width: '250',
      data:{
        title: 'Attenzione',
        content: 'non si può inserire una copertina senza immettere un ISBN valido'
      }
    })
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
