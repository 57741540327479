import { SAVER, Saver } from './saver.provider';
import {
  Inject,
  Injectable,
  Injector,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './storage.service';
import { SuccessOautResponse } from '../models/oauth.model';
import {
  HttpHeaders,
  HttpClient,
} from '@angular/common/http';
import { Subscribable } from 'rxjs';
import { SessionStorageService } from './session.service';
import { download, Download } from './download';
import { take } from 'rxjs/operators';

export interface filters {
  name: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class BookService {
  API_BASE: string = environment[environment.env].base_url;
  cache = null;

  constructor(
    private injector: Injector,
    private httpclient: HttpClient,
    private localStorage: LocalStorageService,
    private session: SessionStorageService,
    private router: Router,
    @Inject(SAVER) private save: Saver
  ) {
    this.initCache();
  }

  public getDashBoard() {
    const sreviceHeaders = this.getHeaders();
    const options = { headers: sreviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/dashboard`,
      options
    );
  }

  /**
   * Prendo i dati di un RSS di cui passo il link
   * @param link link dell'RSS
   * @returns
   */
  public getRssData(link) {
    return this.httpclient.get(link, {
      responseType: 'text',
    });
  }

  /**
   * prendo pdf o blob da backend
   * @param link della chiamata API
   * @param value della risorsa che si vuole prendere
   * @returns
   */
  public getBlob(link, value) {
    const ServiceHeaders = this.getHeadersDownload();
    const options = { headers: ServiceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'Blob' },
      options
    );

    return this.httpclient.get(
      `${this.API_BASE}/${link}/${value}`,
      requestOptions
    );
  }

  /**
   * Metodo generico per il get di una collezione
   * @param resource dultima parte della firma che si vuole chiamare dopo API_BASE
   * @returns riposta della richiesta
   */
  public getCollection(resource) {
    const sreviceHeaders = this.getHeaders();
    const options = { headers: sreviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/${resource}`,
      options
    );
  }

  /**
   * Metodo generico per resituire un singolo elemento di una conllezione
   * @param link della chiamata API
   * @param value della risorsa che si vuole andare a prednere
   * @returns
   */
  public getResource(link, value) {
    const sreviceHeaders = this.getHeaders();
    const options = { headers: sreviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/${link}/${value}`,
      options
    );
  }

  public getResourceFile(link, value) {
    const sreviceHeaders = this.getHeaders();
    const options = { headers: sreviceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'Blob' },
      options
    );

    return this.httpclient.get(
      `${this.API_BASE}/${link}/${value}`,
      requestOptions
    );
  }

  public saveCatalogoBook(payload, cod) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.post(
      `${this.API_BASE}/update-abstract-note/${cod}`,
      payload,
      options
    );
  }

  public getBestSellerBook(
    start,
    stop,
    type,
    limit,
    libreria = 0
  ) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/best-sellers/book/${start}/${stop}/${limit}/${type}/${libreria}`,
      options
    );
  }

  public getBestSellerLibrerie(
    start,
    stop,
    type,
    limit,
    search = null
  ) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    if (search) {
      return this.httpclient.get(
        `${this.API_BASE}/best-sellers/librerie/${start}/${stop}/${limit}/${type}?search=${search}`,
        options
      );
    } else {
      return this.httpclient.get(
        `${this.API_BASE}/best-sellers/librerie/${start}/${stop}/${limit}/${type}`,
        options
      );
    }
  }

  public findBookstore(start, stop, articolo, provincia) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/find-bookstore/${start}/${stop}/${articolo}/${provincia}`,
      options
    );
  }

  public getBookStats(
    code,
    type,
    onlyCda,
    start = null,
    stop = null
  ) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url =
      start !== null && stop !== null
        ? `${this.API_BASE}/stats/libro/${type}/${code}/${onlyCda}/${start}/${stop}`
        : `${this.API_BASE}/stats/libro/${type}/${code}/${onlyCda}`;
    return this.httpclient.get(url, options);
  }

  public setRistampa(ean: string) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/ristampa/${ean}`,
      options
    );
  }

  public getGiri() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/giri`,
      options
    );
  }

  public clienti() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    
    return this.httpclient.get(
      `${this.API_BASE}/clienti`,
      options
    );
  }

  public getGiriByYear(year, successivi = 0) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/giri-anno/${year}/${successivi}`,
      options
    );
  }

  public getVenduto(year) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/venduto/${year}`,
      options
    );
  }

  private async initCache() {
    this.cache = await caches.open(environment.cache);
  }

  public catalogo(page = 1, search = '', order = 'ean') {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/catalogo?page=${page}&order=${order}&filter=${search}`,
      options
    );
  }

  public getAutoriInfo(autori) {
    const payload = {
      authors: autori,
    };
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.post(
      `${this.API_BASE}/autori`,
      payload,
      options
    );
  }

  public getBooks() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/catalogo`,
      options
    );
  }

  public getNovita() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/libri/novita`,
      options
    );
  }

  public getEntityNovita(oid) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/novita/${oid}`,
      options
    );
  }
  public getBook(cod: string) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/catalogo/${cod}`,
      options
    );
  }

  public getCollectionCached(
    collectionName,
    editore?
  ): Request {
    const token: SuccessOautResponse =
      this.session.get('token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
      },
    };
    const jsonResponse = !editore
      ? new Request(
          `${this.API_BASE}/${collectionName}`,
          options
        )
      : new Request(
          `${this.API_BASE}/${collectionName}/${editore}`,
          options
        );
    return jsonResponse;
  }

  public getGiacenza() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/giacenza`,
      options
    );
  }

  public editori(filter = null) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    if (!filter)
      return this.httpclient.get(
        `${this.API_BASE}/editori`,
        options
      );
    else
      return this.httpclient.get(
        `${this.API_BASE}/editori?filter=${filter}`,
        options
      );
  }

  public partizioni(code) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/partizioni/${code}`,
      options
    );
  }
  /** admin part */

  /** ottiene l'ultima data di esportazione delle immagini */
  public getDataExport() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/export/data`,
      options
    );
  }
  /**fa partire il job per la generazione del file zip delle immagini per amazon */
  public getExport(data) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'Blob' },
      options
    );

    return this.httpclient.get(
      `${this.API_BASE}/export/download/${data}`,
      requestOptions
    );
  }
  /** report */
  public editoriReport() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };

    return this.httpclient.get(
      `${this.API_BASE}/report/editori`,
      options
    );
  }

  public anniReport(editore) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };

    return this.httpclient.get(
      `${this.API_BASE}/report/anni/${editore}`,
      options
    );
  }

  public mesiReport(editore, anno) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };

    return this.httpclient.get(
      `${this.API_BASE}/report/mesi/${editore}/${anno}`,
      options
    );
  }

  public downloadReport(editore, anno, mese, tipo) {
    const ServiceHeaders = this.getHeadersDownload();
    const options = { headers: ServiceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'Blob' },
      options
    );

    return this.injector
      .get(HttpClient)
      .get(
        `${this.API_BASE}/report/${tipo}/${anno}/${mese}/${editore}`,
        requestOptions
      );
  }

  /*******/

  public autori(filter) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/autori?filter=${filter}`,
      options
    );
  }

  public collane() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/collane`,
      options
    );
  }

  public getCollane(filter) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/all-collane?filter=${filter}`,
      options
    );
  }

  public generi() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/generi`,
      options
    );
  }

  public argomenti(filter?) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/argomenti?filter=${filter}`,
      options
    );
  }

  public categorie() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/categorie`,
      options
    );
  }

  public rilegature() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/rilegature`,
      options
    );
  }

  public lingue() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/lingue`,
      options
    );
  }

  public getTabellaIva() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/tabella-iva`,
      options
    );
  }

  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });

    return header;
  }

  private getHeadersDownload(): HttpHeaders {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });

    return header;
  }

  public image(ean: string, type: string) {
    //  const options = this.buildRequestOptions({ responseType: 'Blob' });
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'Blob' },
      options
    );
    return this.httpclient.get(
      `${this.API_BASE}/image/ean/${ean}/${type}`,
      requestOptions
    );
  }

  public checkIsbns(isbns) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //

    return this.httpclient.post(
      `${this.API_BASE}/check/isbns`,
      isbns,
      options
    );
  }

  public checkIsbn(isbn) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //

    return this.httpclient.get(
      `${this.API_BASE}/check/isbn/${isbn}`,
      options
    );
  }

  /**
   *
   * @param formData
   * @param id
   */
  public insertCopertina(
    formData: FormData,
    id
  ) {
   

    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header }; //
    return this.httpclient.post(
      `${this.API_BASE}/set-copertina/${id}`,
      formData,
      options
    );
  }

  public insertAdditionalImages(formData: FormData, id) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header }; //
    return this.httpclient.post(
      `${this.API_BASE}/store-additional-images/${id}`,
      formData,
      options
    );
  }

  public viewAdditionalImages(ean) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
      },
    };
    const jsonResponse = new Request(
      `${this.API_BASE}/view-additional-images/${ean}`,
      options
    );
    return jsonResponse;
  }

  public deleteResource(link) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header };

    return this.httpclient.delete(
      `${this.API_BASE}/${link}`,
      options
    );
  }

  public deleteAdditionalImages(isbn) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header }; //
    return this.httpclient.delete(
      `${this.API_BASE}/delete-additional-images/${isbn}`,
      options
    );
  }

  public insertSchedaNovita(
    formData: FormData,
    id,
    isean = 0
  ) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header }; //
    return this.httpclient.post(
      `${this.API_BASE}/set-scheda-novita/${id}/${isean}`,
      formData,
      options
    );
  }

  /**
   * Esegue il check del pdf
   * @param formData
   * @returns
   */
  public checkSchedaNovita(formData: FormData) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header }; //
    return this.httpclient.post(
      `${this.API_BASE}/check/pdffile`,
      formData,
      options
    );
  }

  public setSchedaNovita(formData: FormData, ean: string) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header };
    return this.httpclient.post(
      `${this.API_BASE}/set-scheda-novita/${ean}`,
      formData,
      options
    );
  }

  public catalogoRequest(): Request {
    const token: SuccessOautResponse =
      this.session.get('token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
      },
    };
    const jsonResponse = new Request(
      `${this.API_BASE}/catalogo`,
      options
    );
    return jsonResponse;
  }

  public imageRequest(
    ean: string,
    type: string = ''
  ): Request {
    const token: SuccessOautResponse =
      this.session.get('token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
      },
    };
    if (type === '') {
      const jsonResponse = new Request(
        `${this.API_BASE}/image/ean/${ean}`,
        options
      );
      return jsonResponse;
    } else {
      const jsonResponse = new Request(
        `${this.API_BASE}/image/ean/${ean}`,// /${type}`,
        options
      );
      return jsonResponse;
    }
  }

  public schedaNovitaRequest(ean: string): Request {
    const token: SuccessOautResponse =
      this.session.get('token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
      },
    };
    const response = new Request(
      `${this.API_BASE}/novita/ean/${ean}`,
      options
    );
    return response;
  }

  public insertAutore(payload: any) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //
    return this.httpclient.post(
      `${this.API_BASE}/autore`,
      payload,
      options
    );
  }

  public insertWbook(payload: any) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //
    return this.httpclient.post(
      `${this.API_BASE}/insert-book`,
      payload,
      options
    );
  }

  public saveBook(payload: any, oid) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //
    return this.httpclient.post(
      `${this.API_BASE}/save-book/${oid}`,
      payload,
      options
    );
  }

  public hadleError(error: Error) {
    // se http status 401 allora elimini tutto lo storage e cache e redirigi nella pagina di login

    console.warn(error);
  }

  public getAllegatoPromozionale(editore, giro) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = new Request(
      `${this.API_BASE}/allegatipromozionali/${editore}/${giro}`,
      options
    );
    return response;
  }

  public setAllegatoPromozionale(
    editore,
    giro,
    allegato: FormData
  ) {
    const token: SuccessOautResponse =
      this.session.get('token');
    const header = new HttpHeaders({
      Accept: 'application/pdf',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = { headers: header }; //
    return this.httpclient.post(
      `${this.API_BASE}/set-allegatopromozionale/${editore}/${giro}`,
      allegato,
      options
    );
  }

  public getEditoreAllegatoInfo(anno_giro, numero_giro) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //
    return this.httpclient.get(
      `${this.API_BASE}/editori-resume/${anno_giro}/${numero_giro}`,
      options
    );
  }

  public getEditoreResume(anno_giro, numero_giro) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //
    return this.httpclient.get(
      `${this.API_BASE}/editore-resume/${anno_giro}/${numero_giro}`,
      options
    );
  }

  public deleteBook(id) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders }; //
    return this.httpclient.delete(
      `${this.API_BASE}/novita/${id}`,
      options
    );
  }

  public getCedola(type, anno, mese, cliente) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'blob', filename: 'cedola.xls' },
      options
    );
    return this.httpclient.get(
      `${this.API_BASE}/cedola/${type}/${anno}/${mese}/${cliente}`,
      requestOptions
    );
  }

  public getEditoriCliente(cod_cliente) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/editori-cliente/${cod_cliente}`,
      options
    );
  }

  public unpublish() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.post(
      `${this.API_BASE}/unpublish`, {},
      options
    );
  }

  public publish() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.post(
      `${this.API_BASE}/publish`, {},
      options
    );
  }

  public canDownLoad(anno, numero) {
      const giro = Number(numero + anno);
      const serviceHeaders = this.getHeaders();
      const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/can/download/${giro}`,
      options
    );
  }

  public scaricaArchivio(kind, anno, numero, cliente) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const requestOptions: object = Object.assign(
      {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      },
      options
    );
    return this.httpclient.get(
      `${this.API_BASE}/esportazione/${kind}/${anno}/${numero}/${cliente}`,
      requestOptions
    );
  
  }

  /**Download unico pdf con le schede novita con rich_stama = 1 */
  public donwloadRichSchede(anno: Number, numero: Number) {
    const ServiceHeaders = this.getHeadersDownload();
    const options = { headers: ServiceHeaders };
    const requestOptions: object = Object.assign(
      { responseType: 'Blob' },
      options
    );
    return this.injector
      .get(HttpClient)
      .get(
        `${this.API_BASE}/pdf/schede-richieste/${anno}/${numero}`,
        requestOptions
      );
  }

  /**
   * Ricostruisce tutti i pdeffoni
   * @param anno
   * @param numero
   * @returns
   */
  public rebuildRichSchede(anno: Number, numero: Number) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.get(
      `${this.API_BASE}/pdf/schede-richieste/rebuild/${anno}/${numero}`,
      options
    );
  }

  public updateGiro(oid: Number, anno: Number, giro: Number, ) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.httpclient.patch(
      `${this.API_BASE}/novita/${oid}/${anno}/${giro}`,{},
      options
    );
  }
}
