import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OpenHolidaysService {
  private baseHolidayUrl =
    'https://openholidaysapi.org/PublicHolidays';

  constructor(private http: HttpClient) {}

  getHolidays(dataInizio: string, dataFine: string) {
    return this.http.get(
      `${this.baseHolidayUrl}?countryIsoCode=IT&languageIsoCode=IT&validFrom=${dataInizio}&validTo=${dataFine}&subdivisionCode=IT-ITA`
    );
  }
}
