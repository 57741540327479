import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';
import { BookService } from 'src/app/services/book.service';
import * as _ from 'lodash';
import { HttpEventType } from '@angular/common/http';
import { Subscribable, Subscription } from 'rxjs';
 


@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
  styleUrls: ['./document-download.component.scss']
})
export class DocumentDownloadComponent implements OnInit,OnDestroy {
  /**
   * serve avere in input il giro per determinare i file di cui bisogna eseguire il download.
   */
@Input() giro:Array<any>;
@Input() user:iUser;

subscribe: Subscription = null;
giroselezionato:Giro = null;
percentDone= 0;
downloadable = false;
public loader = {
    pdf : false,
    zip : false,
    downloadable: false
};
/**
 * serve determinare il titolo in base al giro passato in input.
 */
@Input() title:String;
constructor(
  private bookservice: BookService,
  private toaster: ToastrService
) { }

  
  ngOnInit(): void {
    this.downloadAvaible();
  }

  /**
   * Ritorna se i file sono scaricabili confrontanto la data della scadenza del giro cliente
   * con la data di oggi 
   * @returns 
   */
  public downloadAvaible() {
    this.isSelected(this.giro);
    this.loader.downloadable = true;
     this.bookservice.canDownLoad(this.giroselezionato.anno, this.giroselezionato.numero).subscribe((result:any) => {
     this.downloadable = result.data.success; 
     this.loader.downloadable = false;
  }, (fail) => {
     this.loader.downloadable = false;
  });
  } 
  /**
   * Esegue il download degli archivi novità per tipo (PDF|ZIP)
   * @param tipo 
   */
   public async downloadArchivio(tipo:string) { 
    this.isSelected(this.giro);
    this.loader[tipo] = true;
    this.subscribe = this.bookservice.scaricaArchivio(tipo, Number(this.giroselezionato.anno), Number(this.giroselezionato.numero), this.user.code)
    .subscribe((result:any) => {
      if (result.type === HttpEventType.DownloadProgress) {
        this.percentDone = Math.round(100 * result.loaded / result.total); 
      }
      if (result.type === HttpEventType.Response) {
        this.loader[tipo] = false;
          let contentType = (tipo === 'pdf') ? 'application/pdf' : 'application/zip';
          const blob = new Blob([result.body], {type: contentType});
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          let ext = (tipo === 'pdf') ? '.pdf' : '.zip';
          a.download = `giro_${this.giroselezionato.numero}_${this.giroselezionato.anno}${ext}`;
          this.loader[tipo] = false;
          a.click();
      }
  }, (fail) => {
       this.loader[tipo] = false;
       this.toaster.error("Il file non è ancora disponibile, verifica tra qualche ora");
  });
  }

  /**
   * Ritorna un icona in base all'estenzione passata
   * @param ext 
   * @returns 
   */

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

   isSelected(giri) {
    let i = null;
    giri.forEach(giro => {
      i = _.findLastIndex(giri, giro => { return moment(giro.data_scadenza).add(5,'d').format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') });
    if ( giri[i] && giro.descrizione === giri[i].descrizione) {
      this.giroselezionato = giri[i];
    } 
    });
  }

  ngOnDestroy(): void {
      if(this.subscribe){
        this.subscribe.unsubscribe();
      }
  }
}
