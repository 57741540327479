import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-dialog-cambio-giro',
  templateUrl: './dialog-cambio-giro.component.html',
  styleUrls: ['./dialog-cambio-giro.component.scss'],
})
export class DialogCambioGiroComponent implements OnInit {
  changedGiro = false;
  giroSelezionato = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.giroSelezionato = _.find(this.data.giri, {
      code: `${this.data.book.anno_giro}.${this.data.book.numero_giro}`,
    });
  }

  changeGiro(event) {
    this.giroSelezionato = _.find(this.data.giri, {
      code: event.value,
    });
    this.giroSelezionato['oid'] = this.data.book.oid;
  
  }
}
