<div class="container-fluid">
  <div class="row">
    <div class="col-12" *ngIf="action === 'list'">
      <app-recap-lancio
        *ngIf="giro"
        icon="event"
        description="Ultimo giorno utile per la consegna delle schede novità: {{
          giro.data_scadenza | formatDate
        }}"
        title="{{ giro.descrizione }}"
        inizioPren="{{ giro.data_inizio_prenotazione }}"
        finePren="{{ giro.data_fine_prenotazione }}"
        mesiConsigiati="{{ giro.mesi_consigliati }}"
        volumiInDistrDal="{{ giro.data_distribuzione }}"
        numeroGiro = "{{giro.numero}}"
        annoGiro = "{{giro.anno}}"
        type="ep_card-header-primary"
        (OnClickNovita)="showBooks($event)"
      ></app-recap-lancio>
      <div *ngIf="firstGiro != null">
        <app-elenco-novita
          (OnClickEdit)="editBook($event)"
          [firstGiro]="firstGiro"
        ></app-elenco-novita>
      </div>
    </div>
    <div class="col-12" *ngIf="action === 'insert'">
      <book-form (OnClickNovita)="showBooks($event)"></book-form>
    </div>
  </div>
  <div class="row" *ngIf="action === 'edit'">
    <div class="col-12">
      <book-form
        (OnClickNovita)="showBooks($event)"
        [oid]="selectedBook.oid"
        [consegnatoMagazzino]="selectedBook.flag_giacenza === '1'"
        [viewOnly]="!isNovita"
      ></book-form>
    </div>
  </div>
  <div class="row" *ngIf="action === 'ristampa'">
    <div class="col-12">
      <book-form
        (OnClickNovita)="showBooks($event)"
        [book]="bookRistampa"
        [ristampa]="true"
      ></book-form>
    </div>
  </div>
</div>
