import { Giro } from './../../../models/giro.model';
import { BookService } from './../../../services/book.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PdfListDialogComponent } from 'src/app/pages/dashboard/pdf-list-dialog/pdf-list-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-download-schede',
  templateUrl: './download-schede.component.html',
  styleUrls: ['./download-schede.component.scss'],
})
export class DownloadSchedeComponent
  implements OnInit, OnDestroy
{
  public loader = {
    pdf: false,
  };
  giro: Giro = null;
  subscriptions: Subscription[] = [];
  published = false;
  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.giroAttuale();
  }

  /**Download schede novita con richiesta stampa = 1 */
  async downloadSchede() {
    this.loader.pdf = true;

   const sub = this.bookservice
      .donwloadRichSchede(
        Number(this.giro.anno),
        Number(this.giro.numero)
      )
      .subscribe({
        next: (data: any) => {
          const blob = new Blob([data], {
            type: 'application/pdf',
          });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `novita_richieste_${this.giro.anno}_${this.giro.numero}.pdf`;
          // a.download = `novita_richieste_2024_2.pdf`;
          a.click();
          this.loader.pdf = false;
        },
        error: () => {
          this.toastservice.info(
            'File non ancora disponibile riprovare successivamente'
          );
        },
        complete: () => {
          this.loader.pdf = false;
        },
      });

      this.subscriptions.push(sub);
  }

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

  rebuild() {
    const sub = this.bookservice
      .rebuildRichSchede(
        Number(this.giro.anno),
        Number(this.giro.numero)
      )
      .subscribe({
        next: (o) => {
          this.toastservice.info(
            `Ho avviato lo script per la generazione del PDFEFFONE per il giro ${this.giro.numero}/${this.giro.anno}. Riceverai una email a operazione finita`
          );
        },
      });

    this.subscriptions.push(sub);
  }

  async giroAttuale() {
    let giro = null;
    this.loader.pdf = true;
    const sub = this.bookservice
      .getGiriByYear(moment().format('YYYY'), 1)
      .subscribe((data: Array<Giro>) => {
        //cerco l'ultimo giro con la data minore di oggi
        const i = _.findLastIndex(data, (giro) => {
          return (
            moment(giro.data_scadenza).format(
              'YYYY-MM-DD'
            ) <= moment().format('YYYY-MM-DD')
          );
        });
        this.giro = data[i];
        this.bookservice.canDownLoad(this.giro.anno, this.giro.numero).subscribe((result:any) => {
          this.published = result.data.success;
          this.loader.pdf = false;
       }, (fail) => {
        this.loader.pdf = false;
       });
     //   this.loader.pdf = false;
      });

    this.subscriptions.push(sub);
  }

  /** serve a pubblicare il pdeffone */
  publish() {
    this.loader.pdf = true;
    this.bookservice.publish().subscribe({ next: () => {
      this.loader.pdf = false;
      this.published = true;
    }});
  }

  unpublish() {
    this.loader.pdf = true;
    this.bookservice.unpublish().subscribe({ next: () => {
      this.loader.pdf = false;
      this.published = false;
    }});
  }

  pdfList() {
    const sub = this.bookservice
      .getCollection('sfoglia/cedole')
      .subscribe({
        next: (data) => {
          const dialogRef = this.dialog.open(
            PdfListDialogComponent,
            {
              width: '1000px',
              data: {
                pdf: data,
              },
            }
          );
        },
        error: (err) => console.error(err),
      });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
