import { MessageService } from 'src/app/services/message.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BookService } from 'src/app/services/book.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer } from '@angular/platform-browser';
import { simpleResponce } from '../book-form/book-form.component';
import { merge, Observable, of, Subscription } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
@Component({
  selector: 'app-modifica-catalogo',
  templateUrl: './modifica-catalogo.component.html',
  styleUrls: ['./modifica-catalogo.component.scss'],
})
export class ModificaCatalogoComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() code: string = null;
  @Input() book: any = null;
  @Input() process = false;
  @Output() close = new EventEmitter<boolean>();
  public loading = true;
  private copertina = new FormData();
  private schedaNovita = new FormData();
  private additionalImages = new FormData();
  loadingScheda = false;
  immagineCopertina = null;
  subscriptions: Subscription[] = [];
  pdffilename = '';
  novita = null;
  images = [];
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'bold',
        'customClasses',
        'insertImage',
        'insertVideo',
        'link',
        'unlink',
        'toggleEditorMode',
        'clearFormatting',
        'subscript',
        'superscript',
        'aepicker',
      ],
    ],
  };

  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    private domSanitizer: DomSanitizer,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.getBookDetail(this.code);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.process.previousValue &&
      changes.process.currentValue
    ) {
      this.submit();
    }
  }

  setCopertina($event): void {
    this.copertina.delete('image');
    this.copertina.append('image', $event);
  }

  setPdf($event, check = true): void {
    if ($event !== null) {
      this.schedaNovita.delete('scheda');
      this.schedaNovita.append('scheda', $event);
      this.pdffilename = $event.name;
      if (check) {
        this.loadingScheda = true;
        const sub = this.bookservice
          .checkSchedaNovita(this.schedaNovita)
          .subscribe({
            next: (data: simpleResponce) => {
              if (data.success) {
                this.pdffilename = data.filename;
                /* if (saveToBE) {
                  this.saveSchedaNovita(this.book.ean);
                } */
              } else {
                this.unvalidPdf();
              }
            },
            error: (er) => {
              this.unvalidPdf();
            },
            complete: () => (this.loadingScheda = false),
          });

        this.subscriptions.push(sub);
      }
    } else {
      this.schedaNovita.delete('scheda');
    }
  }

  private unvalidPdf() {
    this.toastservice.error(
      'Il pdf che hai caricato non è valido e sarà scartato.'
    );
    this.pdffilename = '';
    this.schedaNovita.delete('scheda');
    this.loadingScheda = false;
  }

  setAdditionalImages($event): void {
    if ($event === 0) {
      this.images.pop();
      if (this.images.length === 0) {
        this.additionalImages.delete('images[]');
      }
    } else {
      this.images.push($event);
    }

    this.additionalImages.delete('images[]');

    for (let image of this.images) {
      this.additionalImages.append('images[]', image);
    }
  }

  getBookDetail(code) {
    this.loading = true;
    const sub = this.bookservice.getBook(code).subscribe(
      (data: any) => {
      
        this.book = data;
        this.loading = false;
        let request = this.bookservice.imageRequest(
          this.book.ean
        );
        fetch(request, { cache: 'reload' })
          .then((response) => response.blob())
          .then((blob) => {
            this.copertina.append('image',blob);
            this.immagineCopertina =
              URL.createObjectURL(blob) ?? null;
          });
        request = this.bookservice.schedaNovitaRequest(
          this.book.ean
        );
        fetch(request, { cache: 'reload' }).then(
          (response) => {
            if (response.status != 404) {
              response.blob().then((blob) => {
                this.setPdf(blob, true);
                this.novita =
                  this.domSanitizer.bypassSecurityTrustResourceUrl(
                    URL.createObjectURL(blob)
                  );
              });
            }
          }
        );
      },
      (error: any) => {
        this.bookservice.hadleError(error);
      }
    );

    this.subscriptions.push(sub);
  }

  saveCopertina(ean) {
    if (this.copertina.has('image')) {
      this.messageservice.setEvent({
        type: 'loadImage',
        event: { ean: ean },
      });
      const sub = this.bookservice
        .insertCopertina(this.copertina, ean)
        .subscribe(
          (data) => {
            this.toastservice.info(
              'Immagine copertina inserita con successo'
            );
            this.messageservice.setEvent({
              type: 'reloadImage',
              event: { ean: ean },
            });
          },
          (error) => {
            this.toastservice.error(
              "Si sono verificati degli errori durante il caricamento dell'immagine di copertina"
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  saveSchedaNovita(ean) {
    const sub = this.bookservice
      .setSchedaNovita(this.schedaNovita, ean)
      .subscribe({
        next: (data) => {

          this.toastservice.success(
            'Successo',
            'Scheda Novità inserita con successo'
          );
        },
        error: (err) => {
          console.error(err);
          this.toastservice.error('Errore inserimento PDF');
        },
      });

    this.subscriptions.push(sub);
  }

  saveAdditionalImages(ean) {
    if (this.additionalImages.has('images[]')) {
      const sub = this.bookservice
        .insertAdditionalImages(this.additionalImages, ean)
        .subscribe(
          (data) => {
            this.toastservice.info(
              'Immagini inserite con successo'
            );
          },
          (error) => {
            this.toastservice.error(
              'Si sono verificati degli errori durante il caricamento delle immagini'
            );
          }
        );

      this.subscriptions.push(sub);
    }
  }

  deleteAdditionalImages(id) {
    const sub = this.bookservice
      .deleteAdditionalImages(id)
      .subscribe((data) => {
        console.log(data);
      });

    this.subscriptions.push(sub);
  }

  public submit() {
    const payload = {
      abstract: this.book.abstract,
      note: this.book.note,
    };

    const confirmSubs = merge(
      this.handleOperationWithToast(
        this.bookservice.saveCatalogoBook(payload, this.book.cod),
        'Salvataggio della scheda catalogo riuscito',
        `Errore nell'inserimento dei dati della scheda`
      ),
      this.handleOperationWithToast(
        this.bookservice.insertCopertina(this.copertina, this.book.ean),
        'Inserimento della copertina riuscito',
        `Errore nell'inserimento dei dati della copertina`
      ),
      this.handleOperationWithToast(
        this.bookservice.setSchedaNovita(this.schedaNovita, this.book.ean),
        'Inserimento della scheda novità riuscito',
        `Errore nell'inserimento dei dati della scheda novità`
      ),
      this.additionalImages.has('images[]')
        ? this.handleOperationWithToast(
            this.bookservice.insertAdditionalImages(this.additionalImages, this.book.ean),
            'Inserimento delle immagini aggiuntive riuscito',
            `Errore nell'inserimento delle immagini aggiuntive`
          )
        : this.handleOperationWithToast(
            this.bookservice.deleteAdditionalImages(this.book.ean),
            'Eliminazione delle immagini aggiuntive riuscita',
            `Errore nell'eliminazione delle immagini aggiuntive`
          )
    );

    const sub = confirmSubs.subscribe({
      next: (val) => {
        console.log(val);
      },
      error: (err) => {
        console.error(err);
      },
      complete: () =>  this.close.emit(true),
    });

  
    this.subscriptions.push(sub);
  }

  downloadPdf() {
    const request = this.bookservice.schedaNovitaRequest(
      this.book.ean.toString()
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${this.book.ean}.pdf`;
          a.click();
        });
      }
    });
  }

  /**
   * 
   * @param operation$ Observable che andrò a sotoscrivere
   * @param successMessage messagio in caso di successo
   * @param errorMessage messaggio in caso di errore
   * @returns 
   */
  handleOperationWithToast<T>(operation$: Observable<T>, successMessage: string, errorMessage: string): Observable<T> {
    return operation$.pipe(
      tap(() => {
        this.toastservice.success(successMessage); 
      }),
      catchError((err) => {
        this.toastservice.error(errorMessage); 
        return of(null);; 
      })
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
