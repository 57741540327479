import { BookService } from './../../services/book.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { dataTable } from '../../models/dataTable.model';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/storage.service';
import * as moment from 'moment';
import * as timezone from 'moment-timezone';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';
import { FormControl, FormGroup } from '@angular/forms';
import { GiacenzaDialogComponent } from 'src/app/components/giacenza-dialog/giacenza-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() user: iUser = null;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private storage: LocalStorageService,
    private bookservice: BookService
  ) {}
  selectedType: number = 88;
  limit = '30';
  subscriptions: Subscription[] = [];
  types = [
    { cod: 88, description: 'SellIn', checked: true },
    { cod: 89, description: 'SellOut', checked: false },
  ];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  loadingItems = {
    catalogo: false,
    novita: false,
    graficoGiacenze: false,
    graficoVenduto: false,
    tabella: false,
    giri: false,
    toplibri: false,
    editoriAllegato: false,
  };
  giri: Array<Giro>;
  giriCliente: Array<Giro>;
  giriResume = [];
  giroResumeSelected = null;
  giroselezionato: Giro;
  libriCatalogo = 0;
  libriNovita = 0;
  editoriAllegato: dataTable = {
    header: [
      'Editore',
      'N. Novità',
      'N. Ristampa',
      'N. Rich. Stampa',
      'Allegato Promozionale',
    ],
    rows: [],
    rowClick: [],
    rowsExport: [],
    rowTotale: [],
  };
  dataTable: dataTable = {
    header: ['Titolo', 'Quantità'],
    rows: [],
  };
  chartOption = {
    legend: {
      left: 10,
      data: ['SellIn', 'SellOut'],
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
    },
    yAxis: {
      type: 'value',
    },

    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    series: [
      {
        name: 'SellIn',
        data: [],
        type: 'line',
      },
      {
        name: 'SellOut',
        data: [],
        type: 'line',
      },
    ],
  };

  pieOption = {
    tooltip: {
      trigger: 'item',
    },

    series: [
      {
        name: 'Giacenza',
        type: 'pie',
        radius: '50%',
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  /** data ultima esportazione delle immagini */
  lastData = null;
  execDate = null;

  getSelectedType(event) {
    this.selectedType = event.value;
    this.doReport();
  }

  changeDate() {
    if (
      moment(this.range.value.start).isValid() &&
      moment(this.range.value.end).isValid()
    ) {
      this.doReport();
    }
  }

  /**
   * getGiri
   */
  async getGiri() {
    this.loadingItems.giri = true;
    const sub = this.bookservice
      .getGiri()
      .subscribe((data: Array<Giro>) => {
        this.loadingItems.giri = false;
        this.giri = data;
        this.storage.set('firstGiro', data[0]);
        this.getCatalogo();
        this.getVenduto();
      });

    this.subscriptions.push(sub);
  }

  getVenduto() {
    this.loadingItems.graficoVenduto = true;
    const year = Number(moment().format('Y'));
    const sub = this.bookservice
      .getVenduto(year)
      .subscribe((data: any) => {
        this.loadingItems.graficoVenduto = false;
        data.sellIn.forEach((element, index) => {
          this.chartOption.series[0].data[
            Number(element.mese) - 1
          ] = element.quantita;
        });
        data.sellOut.forEach((element, index) => {
          this.chartOption.series[1].data[
            Number(element.mese) - 1
          ] = element.quantita;
        });
      });
    this.subscriptions.push(sub);
    this.doReport();
  }

  dataView(opt) {
    var axisData = opt.xAxis[0].data;
    var series = opt.series;
    var table =
      '<table style="width:100%;text-align:center"><tbody><tr>' +
      '<td>Time:</td>' +
      '<td>' +
      series[0].name +
      '</td>' +
      '<td>' +
      series[1].name +
      '</td>' +
      '</tr>';
    for (var i = 0, l = axisData.length; i < l; i++) {
      table +=
        '<tr>' +
        '<td>' +
        axisData[i] +
        '</td>' +
        '<td>' +
        series[0].data[i] +
        '</td>' +
        '<td>' +
        series[1].data[i] +
        '</td>' +
        '</tr>';
    }
    table += '</tbody></table>';
    return table;
  }

  goto(link: string): void {
    this.router.navigate([link]);
  }

  getCatalogo() {
    if (this.user.isEditore()) {
      this.loadingItems.catalogo = true;
      const sub = this.bookservice
        .getBooks()
        .subscribe((data: any) => {
          this.loadingItems.catalogo = false;
          this.libriCatalogo = data.length;
          this.getGiacenza();
        });

      this.subscriptions.push(sub);
    }
  }

  public getGiacenza() {
    this.loadingItems.graficoGiacenze = true;
    const sub = this.bookservice
      .getGiacenza()
      .subscribe((data: Array<any>) => {
        data.forEach((element) => {
          this.loadingItems.graficoGiacenze = false;
          this.pieOption.series[0].data.push({
            name: element.descrizione,
            value: element.giacenza,
          });
        });
      });

    this.subscriptions.push(sub);
  }
  /** i top libri */
  async doReport() {
    const start = moment(this.range.value.start).format(
      'YYYYMMDD'
    );
    const stop = moment(this.range.value.end).format(
      'YYYYMMDD'
    );
    this.loadingItems.toplibri = true;

    const sub = this.bookservice
      .getBestSellerBook(
        start,
        stop,
        this.selectedType,
        this.limit
      )
      .subscribe((data: Array<any>) => {
        this.dataTable.rows = [];
        this.dataTable.rowClick = [];
        if (data.length) {
          data.forEach((item) => {
            this.dataTable.rows.push([
              item.titolo,
              Number(item.quantita),
            ]);
            this.dataTable.rowClick.push([
              item.titolo,
              item.codice,
            ]);
          });
        }
        this.loadingItems.toplibri = false;
      });

    this.subscriptions.push(sub);
  }

  openStatsBook($event) {
    const dialogRef = this.dialog.open(
      GiacenzaDialogComponent,
      {
        data: { codice: $event[1], titolo: $event[0] },
        height: 'auto',
        width: 'auto',
      }
    );

    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {});
  }

  public getDataExport() {
    const sub = this.bookservice
      .getDataExport()
      .subscribe((r: any) => {
        if (r.data_richiesta) {
          this.lastData = timezone(
            moment(r.data_esecuzione, 'YYYY-MM-DD HH:mm')
          )
            .tz('Europe/Rome')
            .format('DD-MM-YYYY HH:mm');
          this.execDate = timezone(r.data_esecuzione)
            .tz('Europe/Rome')
            .format('DD-MM-YYYY HH:mm');
        }
      });

    this.subscriptions.push(sub);
  }

  /**Ritorna tutti i giri partendo dall'anno corrente
   *
   * @param year string     anno da cui parto a prendere i giri
   *
   */
  async getGiriYear(year: string) {
    this.loadingItems.giri = true;
    const sub = this.bookservice
      .getGiriByYear(year, 1)
      .subscribe((data: Array<Giro>) => {
        this.loadingItems.giri = true;
        this.giriCliente = data;

        if (this.user.isAdmin()) {
          this.selectedGiroAdmin(data);
        }
        if (this.user.isCliente()) {
          this.selectedGiroCliente(data);
        }
        data.forEach((giro) => {
          this.giriResume.push({
            cod: giro.anno + '.' + giro.numero,
            description: giro.descrizione,
          });
        });
      });

    this.subscriptions.push(sub);
  }

  selectedGiroCliente(giri) {
    let i = null;
    giri.forEach((giro) => {
      i = giri.findIndex(
        (giro) =>
          moment(giro.data_fine_prenotazione).format(
            'YYYY-MM-DD'
          ) >= moment().format('YYYY-MM-DD')
      );
      if (
        giri[i] &&
        giro.descrizione === giri[i].descrizione
      ) {
        this.giroselezionato = giri[i];
        this.storage.set(
          'selectedGiro',
          this.giroselezionato
        );
      }
    });
  }

  selectedGiroAdmin(giri) {
    const i = _.findLastIndex(giri, (giro) => {
      return (
        moment(giro.data_scadenza).format('YYYY-MM-DD') <=
        moment().format('YYYY-MM-DD')
      );
    });

    this.giroselezionato = giri[i];
    this.storage.set('giro', this.giroselezionato);
  }

  ngOnInit(): void {
    if (this.user.isCliente()) {
      this.getGiriYear(moment().format('YYYY'));
    }
    this.loadingItems.novita = true;
    if (this.user.isEditore()) {
      const sub = this.bookservice
        .getNovita()
        .subscribe((data: any) => {
          this.libriNovita = data.length;
          this.loadingItems.novita = false;
        });

      this.subscriptions.push(sub);
    }

    const start = moment().subtract(1, 'M').toDate();
    const stop = moment().toDate();
    this.range.controls['start'].setValue(start);
    this.range.controls['end'].setValue(stop);
    if (this.user.isEditore()) {
      this.getGiri();
    }

    if (this.user.isAdmin()) {
      /**
       * seleziono i giri partando dall'anno precedente ad oggi
       * serve per la selezione del giro nel rendiconto editore dell'admin
       */
      this.getGiriYear(
        moment().subtract(1, 'years').format('YYYY')
      );
      this.getDataExport();
      this.getVenduto();
      //this.getEditoreAllegatoInfo(this.giroResumeSelected);
    }
  }

  /**
   *
   */
  async getEditoreAllegatoInfo(event) {
    const info = event.value.split('.');
    const anno = info[0];
    const numero = info[1];
    let sumNovita = 0;
    this.giroResumeSelected = anno + numero;
    this.loadingItems.editoriAllegato = true;
    const sub = this.bookservice
      .getEditoreAllegatoInfo(anno, numero)
      .subscribe((data: Array<any>) => {
        //ordino per editore
        data.sort((a, b) =>
          a.editore.localeCompare(b.editore)
        );
        this.editoriAllegato.rows = [];
        this.editoriAllegato.rowClick = [];
        this.editoriAllegato.rowsExport = [];
        this.editoriAllegato.rowTotale = [];
        data.forEach((item) => {
          this.editoriAllegato.rows.push([
            item.editore,
            Number(item.novita),
            Number(item.ristampa),
            Number(item.rich_stampa),
            Number(item.allegato) == 1 ? 'icon' : '',
          ]);

          this.editoriAllegato.rowClick.push([
            item.codice,
            item.editore,
          ]);

          this.editoriAllegato.rowsExport.push(
            {
              Editore: item.editore,
              'N. Novità': item.novita,
              'N. Ristampa': item.ristampa,
              'N. Rich. Stampa': item.rich_stampa,
              allegato_promozionale: Number(item.allegato),
            }
            //== 1 ? '✅' : ''
          );
        });
        this.editoriAllegato.rowTotale.push(
          'Totale',
          _.sumBy(data, (novita) => {
            return Number(novita.novita);
          }),
          _.sumBy(data, (ristampa) => {
            return Number(ristampa.ristampa);
          }),
          _.sumBy(data, (rich_stampa) => {
            return Number(rich_stampa.rich_stampa);
          }),
          _.sumBy(data, (allegato) => {
            return Number(allegato.allegato);
          })
        );
        this.loadingItems.editoriAllegato = false;
      });

      this.subscriptions.push(sub);
  }

  downloadAllegato($event) {
    const cod_editore = $event[0];
    const desc_editore = $event[1];
    const i = _.findIndex(
      this.editoriAllegato.rowsExport,
      (editore) => {
        return editore.Editore === desc_editore;
      }
    );
    const allegato =
      this.editoriAllegato.rowsExport[i]
        .allegato_promozionale;
    if (allegato == 1) {
      //const giro =  this.giro.anno+this.giro.numero;
      const giro = this.giroResumeSelected;
      const request =
        this.bookservice.getAllegatoPromozionale(
          cod_editore,
          giro
        );
      fetch(request, { cache: 'reload' }).then(
        (response) => {
          if (response.status != 404) {
            response.blob().then((blob) => {
              const a = document.createElement('a');
              a.href = URL.createObjectURL(blob);
              a.download = `${cod_editore}_${giro}.pdf`;
              a.click();
            });
          }
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
