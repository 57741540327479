<mat-form-field *ngIf="!loading" class="full-width">
    <mat-label
      >Data commercializzazione</mat-label
    >
    <input
      matInput
      [min]="minDate"
      [disabled]="viewOnly"
      required
      [matDatepicker]="datapubblicazione"
      [matDatepickerFilter]="holidayDateFilter"
      id="datapubblicazione"
      [(ngModel)]="dataPubblicazione"
      (ngModelChange)="cambioDataEmit.emit($event)"
      name="datapubblicazione"
      readonly
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="datapubblicazione"
      *ngIf="!viewOnly"
    ></mat-datepicker-toggle>
    <mat-datepicker
      #datapubblicazione
    ></mat-datepicker>
  </mat-form-field>
  