<div
  [class]="type"
  class="img-container"
>
  <img
    mat-card-image
    [src]="frontImageSrc | safeResourceUrl"
    loading="lazy"
    *ngIf="!loader"
    onerror="this.src='../../../assets/images/missing.png'"
  />
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loader"
  ></mat-progress-bar>
</div>
