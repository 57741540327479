//  This file can be replaced during build by using the `fileReplacements` array.
//  `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
//  The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  env: 'stage',
  version: '13022025.2',
  cache: 'editportal',
  local: {
    client_id: 2,
    client_secret:
      'cNEUbIpEzjWZqwWZ5xw8ccyZQqZEcNdAea0R6Go8',
    oauth_base_url: 'http://127.0.0.1:8000/oauth',
    auth_base_url: 'http://127.0.0.1:8000/api/auth',
    base_url: 'http://127.0.0.1:8000/api',
    domain: 'http://127.0.0.1:8000',
    version: 'v1',
  },

  stage: {
    client_id: 2,
    client_secret:
      'cNEUbIpEzjWZqwWZ5xw8ccyZQqZEcNdAea0R6Go8',
    oauth_base_url:
      'https://portalecda-api.dscsrl.it:8000/oauth',
    auth_base_url:
      'https://portalecda-api.dscsrl.it:8000/api/auth',
    base_url: 'https://portalecda-api.dscsrl.it:8000/api',
    domain: 'https://portalecda-api.dscsrl.it:8000',
    version: 'v1',
  },

  test: {
    client_id: 2,
    client_secret:
      'cNEUbIpEzjWZqwWZ5xw8ccyZQqZEcNdAea0R6Go8',
    oauth_base_url: 'https://apitest.dscsrl.eu:8000/oauth',
    auth_base_url:
      'https://apitest.dscsrl.eu:8000/api/auth',
    base_url: 'https://apitest.dscsrl.eu:8000/api',
    domain: 'https://apitest.dscsrl.eu:8000',
    version: 'v1',
    socket: '',
  },
  /*  antonio: {
    client_id: 2,
    client_secret: 'cNEUbIpEzjWZqwWZ5xw8ccyZQqZEcNdAea0R6Go8',
    oauth_base_url: 'http://192.168.8.168:8000/oauth',
    auth_base_url: 'http://192.168.8.168:8000/api/auth',
    base_url: 'http://192.168.8.168:8000/api',
    domain: 'http://192.168.8.168:8000',
    version: 'v1', 
  }, */
};
///
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
