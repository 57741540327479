import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { dataTable } from '../../models/dataTable.model';
import * as moment from 'moment';
import { BookService } from 'src/app/services/book.service';
import { C } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
@Component({
  selector: 'app-tabella',
  templateUrl: './tabella.component.html',
  styleUrls: ['./tabella.component.scss'],
})
export class TabellaComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = '';
  @Input() type = '';
  @Input() data: dataTable;
  @Input() loading = false;
  @Input() scroll: boolean = false;
  @Input() export: boolean = false;
  @Input() hintTooltip: string = '';
  @Output() rowClick = new EventEmitter<any>();
  public nodataColspan;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.nodataColspan = this.data.header.length;
  }

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

  format(value) {
    if (this.isNumber(value)) {
      return 'right';
    }
    if (this.isDate(value)) {
      return 'center';
    }
    if (value === 'icon') {
      return 'center';
    }
    return 'left';
  }

  isDate(value) {
    if (moment(value, 'DD-MM-YYYY', true).isValid()) {
      return true;
    }
    return false;
  }

  isNumber(value) {
    return Number(value) === value;
  }

  clickRow(index) {
    if (this.data.rowClick) {
      this.rowClick.emit(this.data.rowClick[index]);
    }
  }

}
